export default {
  RESET_ALL_REDUCERS: 'RESET_ALL_REDUCERS',
  CLEAR_STATE: 'CLEAR_STATE',
  SET_IS_FROM_DEVICES: 'SET_IS_FROM_DEVICES',
  ON_SELECT_REPORT: 'ON_SELECT_REPORT',
  SYNC_REPORT: 'SYNC_REPORT',
  REVERSE_SYNC_REPORT: 'REVERSE_SYNC_REPORT',
  UPDATE_SYNC_REPORT: 'UPDATE_SYNC_REPORT',
  CLEAR_SYNC_REPORT: 'CLEAR_SYNC_REPORT',
  UPDATE_REAL_QUESTION: 'UPDATE_REAL_QUESTION',
  UPDATE_REALTIME_SUCCESS: 'UPDATE_REALTIME_SUCCESS',
  UPDATE_REALTIME_FAILURE: 'UPDATE_REALTIME_FAILURE',
  UPDATE_REALTIME_REQUEST: 'UPDATE_REALTIME_REQUEST',
  ADD_SYSTEM_QR: 'ADD_SYSTEM_QR',
  ADD_DEVICES_QR: 'ADD_DEVICES_QR',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  CLEAR_DEVICE_DETAILS_DATA: 'CLEAR_DEVICE_DETAILS_DATA',
  SET_TERMS_AND_CONDITION_REQUEST: 'SET_TERMS_AND_CONDITION_REQUEST',
  SET_TERMS_AND_CONDITION_SUCCESS: 'SET_TERMS_AND_CONDITION_SUCCESS',
  SET_TERMS_AND_CONDITION_FAILURE: 'SET_TERMS_AND_CONDITION_FAILURE',
  SET_PRIVACY_POLICY: 'SET_PRIVACY_POLICY',
  GET_QR_CODE_RESPONSE_SUCCESS: 'GET_QR_CODE_RESPONSE_SUCCESS',
  GET_QR_CODE_RESPONSE_FAILURE: 'GET_QR_CODE_RESPONSE_FAILURE',
  GET_QR_CODE_RESPONSE_REQUEST: 'GET_QR_CODE_RESPONSE_REQUEST',
  CLEAR_QR_CODE_RESPONSE: 'CLEAR_QR_CODE_RESPONSE',
  SAVE_REPORT_SUCCESS: 'SAVE_REPORT_SUCCESS',
  SAVE_REPORT_FAILURE: 'SAVE_REPORT_FAILURE',
  SAVE_REPORT_REQUEST: 'SAVE_REPORT_REQUEST',
  SET_TERMS_AND_CONDITION: 'SET_TERMS_AND_CONDITION',
  SET_LOCALE_EN: 'SET_LOCALE_EN',
  SET_LOCALE_ES: 'SET_LOCALE_ES',
  SET_IS_PROMPT: 'SET_IS_PROMPT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  CLEAR_TRANSACTIONS: 'CLEAR_TRANSACTIONS',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  CLEAR_LOGOUT: 'CLEAR_LOGOUT',
  GET_OLDER_NOTIFICATIONS_SUCCESS: 'GET_OLDER_NOTIFICATIONS_SUCCESS',
  GET_OLDER_NOTIFICATIONS_FAILURE: 'GET_OLDER_NOTIFICATIONS_FAILURE',
  GET_OLDER_NOTIFICATIONS_REQUEST: 'GET_OLDER_NOTIFICATIONS_REQUEST',
  OLDER_NOTIFICATIONS_RESET: 'OLDER_NOTIFICATIONS_RESET',
  SET_NOTIFICATION_COUNT: 'SET_NOTIFICATION_COUNT',
  SET_NOTIFICATION_COUNT_SUCCESS: 'SET_NOTIFICATION_COUNT_SUCCESS',
  SET_NOTIFICATION_COUNT_FAILURE: 'SET_NOTIFICATION_COUNT_FAILURE',
  SET_NOTIFICATION_COUNT_REQUEST: 'SET_NOTIFICATION_COUNT_REQUEST',
  GET_TRANSACTION_DETAIL_SUCCESS: 'GET_TRANSACTION_DETAIL_SUCCESS',
  GET_TRANSACTION_DETAIL_REQUEST: 'GET_TRANSACTION_DETAIL_REQUEST',
  GET_TRANSACTION_DETAIL_FAILURE: 'GET_TRANSACTION_DETAIL_FAILURE',
  DELETE_TRANSACTION_SUCCESS: 'DELETE_TRANSACTION_SUCCESS',
  DELETE_TRANSACTION_REQUEST: 'DELETE_TRANSACTION_REQUEST',
  DELETE_TRANSACTION_FAILURE: 'DELETE_TRANSACTION_FAILURE',
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  CLEAR_RECURRING_INSPECTION_DETAILS: 'CLEAR_RECURRING_INSPECTION_DETAILS',
  SAVE_PART_SUCCESS: 'SAVE_PART_SUCCESS',
  SAVE_PART_FAILURE: 'SAVE_PART_FAILURE',
  SAVE_PART_REQUEST: 'SAVE_PART_REQUEST',
  CLEAR_PART_REDUCER: 'CLEAR_PART_REDUCER',
  CLEAR_QUICKBOOK_AUTH: 'CLEAR_QUICKBOOK_AUTH',
  CLEAR_QUICKBOOK_DETAILS: 'CLEAR_QUICKBOOK_DETAILS',
  QUICKBOOK_AUTH_SUCCESS: 'QUICKBOOK_AUTH_SUCCESS',
  QUICKBOOK_AUTH_FAILURE: 'QUICKBOOK_AUTH_FAILURE',
  QUICKBOOK_AUTH_REQUEST: 'QUICKBOOK_AUTH_REQUEST',
  UNLINK_QUICKBOOK_SUCCESS: 'UNLINK_QUICKBOOK_SUCCESS',
  SYNC_WITH_QUICKBOOKS_SUCCESS: 'SYNC_WITH_QUICKBOOKS_SUCCESS',
  SYNC_WITH_QUICKBOOKS_REQUEST: 'SYNC_WITH_QUICKBOOKS_REQUEST',
  SYNC_WITH_QUICKBOOKS_FAILURE: 'SYNC_WITH_QUICKBOOKS_FAILURE',
  FETCH_SUBSCRIPTION: 'FETCH_SUBSCRIPTION',
  CLEAR_WAYPOINT_DISTANCE: 'CLEAR_WAYPOINT_DISTANCE',
  UNLINK_QUICKBOOK_REQUEST: 'UNLINK_QUICKBOOK_REQUEST',
  UNLINK_QUICKBOOK_FAILURE: 'UNLINK_QUICKBOOK_FAILURE',
  GET_QUICKBOOK_DETAILS_REQUEST: 'GET_QUICKBOOK_DETAILS_REQUEST',
  GET_QUICKBOOK_DETAILS_SUCCESS: 'GET_QUICKBOOK_DETAILS_SUCCESS',
  GET_QUICKBOOK_DETAILS_FAILURE: 'GET_QUICKBOOK_DETAILS_FAILURE',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGOUT: 'USERS_LOGOUT',
  CLEAR_SETTINGS: 'CLEAR_SETTINGS',
  CLEAR_DEFICIENCY: 'CLEAR_DEFICIENCY',
  CLEAR_CREATE_JOB: 'CLEAR_CREATE_JOB',
  RESET_PASSWORD_INITIAL: 'RESET_PASSWORD_INITIAL',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  GET_REVENUE_REQUEST: 'GET_REVENUE_REQUEST',
  GET_REVENUE_SUCCESS: 'GET_REVENUE_SUCCESS',
  GET_REVENUE_FAILURE: 'GET_REVENUE_FAILURE',
  GET_REVENUE_RESET: 'GET_REVENUE_RESET',
  GET_JOBS_CHART_DATA_REQUEST: 'GET_JOBS_CHART_DATA_REQUEST',
  GET_JOBS_CHART_DATA_SUCCESS: 'GET_JOBS_CHART_DATA_SUCCESS',
  GET_JOBS_CHART_DATA_FAILURE: 'GET_JOBS_CHART_DATA_FAILURE',
  GET_JOBS_CHART_DATA_RESET: 'GET_JOBS_CHART_DATA_RESET',
  GET_MY_JOB_LIST_SUCCESS: 'GET_MY_JOB_LIST_SUCCESS',
  GET_MY_JOB_LIST_REQUEST: 'GET_MY_JOB_LIST_REQUEST',
  GET_MY_JOB_LIST_FAILURE: 'GET_MY_JOB_LIST_FAILURE',
  GET_JOB_LIST_SUCCESS: 'GET_JOB_LIST_SUCCESS',
  GET_DEFICIENCY_DETAILS_SUCCESS: 'GET_DEFICIENCY_DETAILS_SUCCESS',
  GET_DEFICIENCY_DETAILS_REQUEST: 'GET_DEFICIENCY_DETAILS_REQUEST',
  GET_DEFICIENCY_DETAILS_FAILURE: 'GET_DEFICIENCY_DETAILS_FAILURE',
  UPDATE_TIME_SHEET_AGAIN: 'UPDATE_TIME_SHEET_AGAIN',
  UPDATE_DEFICIENCY_SUCCESS: 'UPDATE_DEFICIENCY_SUCCESS',
  UPDATE_DEFICIENCY_REQUEST: 'UPDATE_DEFICIENCY_REQUEST',
  UPDATE_DEFICIENCY_FAILURE: 'UPDATE_DEFICIENCY_FAILURE',
  GET_DEFICIENCY_LIST_SUCCESS: 'GET_DEFICIENCY_LIST_SUCCESS',
  GET_DEFICIENCY_LIST_REQUEST: 'GET_DEFICIENCY_LIST_REQUEST',
  GET_DEFICIENCY_LIST_FAILURE: 'GET_DEFICIENCY_LIST_FAILURE',
  DELETE_DEFICIENCY_REQUEST: 'DELETE_DEFICIENCY_REQUEST',
  DELETE_DEFICIENCY_SUCCESS: 'DELETE_DEFICIENCY_SUCCESS',
  DELETE_DEFICIENCY_FAILURE: 'DELETE_DEFICIENCY_FAILURE',
  GET_JOB_LIST_REQUEST: 'GET_JOB_LIST_REQUEST',
  GET_JOB_LIST_FAILURE: 'GET_JOB_LIST_FAILURE',
  PRINT_JOB_LIST_SUCCESS: 'PRINT_JOB_LIST_SUCCESS',
  PRINT_JOB_LIST_REQUEST: 'PRINT_JOB_LIST_REQUEST',
  PRINT_JOB_LIST_FAILURE: 'PRINT_JOB_LIST_FAILURE',
  PRINT_JOB_LIST_FINISHED: 'PRINT_JOB_LIST_FINISHED',
  GET_TRANSACTION_LIST_SUCCESS: 'GET_TRANSACTION_LIST_SUCCESS',
  GET_TRANSACTION_LIST_REQUEST: 'GET_TRANSACTION_LIST_REQUEST',
  GET_TRANSACTION_LIST_FAILURE: 'GET_TRANSACTION_LIST_FAILURE',
  GET_CLIENT_LIST_SUCCESS: 'GET_CLIENT_LIST_SUCCESS',
  GET_CLIENT_LIST_REQUEST: 'GET_CLIENT_LIST_REQUEST',
  GET_CLIENT_LIST_FAILURE: 'GET_CLIENT_LIST_FAILURE',
  ADD_EMPLOYEE_REQUEST: 'ADD_EMPLOYEE_REQUEST',
  ADD_EMPLOYEE_SUCCESS: 'ADD_EMPLOYEE_SUCCESS',
  ADD_EMPLOYEE_FAILURE: 'ADD_EMPLOYEE_FAILURE',
  SHOW_TEAM_REQUEST: 'SHOW_TEAM_REQUEST',
  SHOW_INITIAL_TEAM_REQUEST: 'SHOW_INITIAL_TEAM_REQUEST',
  SHOW_TEAM_SUCCESS: 'SHOW_TEAM_SUCCESS',
  SHOW_TEAM_FAILURE: 'SHOW_TEAM_FAILURE',
  FETCH_MEMBER_REQUEST: 'FETCH_MEMBER_REQUEST',
  FETCH_MEMBER_SUCCESS: 'FETCH_MEMBER_SUCCESS',
  FETCH_MEMBER_FAILURE: 'FETCH_MEMBER_FAILURE',
  EDIT_MEMBER_REQUEST: 'EDIT_MEMBER_REQUEST',
  EDIT_MEMBER_SUCCESS: 'EDIT_MEMBER_SUCCESS',
  EDIT_MEMBER_FAILURE: 'EDIT_MEMBER_FAILURE',
  SEARCH_EMPLOYEE_REQUEST: 'SEARCH_EMPLOYEE_REQUEST',
  SEARCH_EMPLOYEE_SUCCESS: 'SEARCH_EMPLOYEE_SUCCESS',
  SEARCH_EMPLOYEE_FAILURE: 'SEARCH_EMPLOYEE_FAILURE',
  RESET_TEAM_REDUCER: 'RESET_TEAM_REDUCER',
  GET_TEAM_GPS_DATA_INITIAL_REQUEST: 'GET_TEAM_GPS_DATA_INITIAL_REQUEST',
  GET_TEAM_GPS_DATA_REQUEST: 'GET_TEAM_GPS_DATA_REQUEST',
  GET_TEAM_GPS_DATA_SUCCESS: 'GET_TEAM_GPS_DATA_SUCCESS',
  GET_TEAM_GPS_DATA_FAILURE: 'GET_TEAM_GPS_DATA_FAILURE',
  DELETE_EMPLOYEE_REQUEST: 'DELETE_EMPLOYEE_REQUEST',
  DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
  DELETE_EMPLOYEE_FAILURE: 'DELETE_EMPLOYEE_FAILURE',
  SET_SELECTED_CUSTOMER: 'SET_SELECTED_CUSTOMER',
  SET_SUBMENU_VISIBILITY: 'SET_SUBMENU_VISIBILITY',
  RESET_SELECTED_CUSTOMER: 'RESET_SELECTED_CUSTOMER',
  GET_CLIENT_OR_OWNER_DETAILS_SUCCESS: 'GET_CLIENT_OR_OWNER_DETAILS_SUCCESS',
  GET_CLIENT_OR_OWNER_DETAILS_FAILURE: 'GET_CLIENT_OR_OWNER_DETAILS_FAILURE',
  GET_CLIENT_OR_OWNER_DETAILS_REQUEST:
    'GET_CLIENT_OR_OWNER_DETASET_JOB_DETAILSILS_REQUEST',
  SET_CLIENT_DETAILS_SUCCESS: 'SET_CLIENT_DETAILS_SUCCESS',
  SET_OWNER_DETAILS_SUCCESS: 'SET_OWNER_DETAILS_SUCCESS',
  SET_CLIENT_OR_OWNER_DETAILS_REQUEST: 'SET_CLIENT_OR_OWNER_DETAILS_REQUEST',
  SET_CLIENT_OR_OWNER_DETAILS_FAILURE: 'SET_CLIENT_OR_OWNER_DETAILS_FAILURE',
  FETCH_TICKETS_REQUEST: 'FETCH_TICKETS_REQUEST',
  FETCH_TICKETS_SUCCESS: 'FETCH_TICKETS_SUCCESS',
  FETCH_TICKETS_FAILURE: 'FETCH_TICKETS_FAILURE',
  FETCH_TICKETS_CLEAR: 'FETCH_TICKETS_CLEAR',
  UPDATE_EMPLOYEE_STATUS_REQUEST: 'UPDATE_EMPLOYEE_STATUS_REQUEST',
  UPDATE_EMPLOYEE_STATUS_SUCCESS: 'UPDATE_EMPLOYEE_STATUS_SUCCESS',
  UPDATE_EMPLOYEE_STATUS_FAILURE: 'UPDATE_EMPLOYEE_STATUS_FAILURE',
  UPDATE_HOURS_REQUEST: 'UPDATE_HOURS_REQUEST',
  UPDATE_HOURS_SUCCESS: 'UPDATE_HOURS_SUCCESS',
  UPDATE_HOURS_FAILURE: 'UPDATE_HOURS_FAILURE',
  SHOW_SHEET_REQUEST: 'SHOW_SHEET_REQUEST',
  SHOW_SHEET_SUCCESS: 'SHOW_SHEET_SUCCESS',
  SHOW_SHEET_FAILURE: 'SHOW_SHEET_FAILURE',
  DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_FAILURE: 'DELETE_NOTIFICATION_FAILURE',
  DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
  CLEAR_DELETE_NOTIFICATION: 'CLEAR_DELETE_NOTIFICATION',
  ADD_JOBS_SUBMIT_DATA_SUCCESS: 'ADD_JOBS_SUBMIT_DATA_SUCCESS',
  ADD_JOBS_SUBMIT_DATA_ERROR: 'ADD_JOBS_SUBMIT_DATA_ERROR',
  ADD_JOBS_SUBMIT_DATA_REQUEST: 'ADD_JOBS_SUBMIT_DATA_REQUEST',
  ADD_JOB_UPDATE_FIELD: 'ADD_JOB_UPDATE_FIELD',
  ADD_JOB_FIELD_ERROR: 'ADD_JOB_FIELD_ERROR',
  ADD_JOB_CLEAR_API_STATE: 'ADD_JOB_CLEAR_API_STATE',
  ADD_JOB_CLEAR_STATE: 'ADD_JOB_CLEAR_STATE',
  SUBSCRIPTION_PLAN_SUCCESS: 'SUBSCRIPTION_PLAN_SUCCESS',
  SUBSCRIPTION_PLAN_FAILURE: 'SUBSCRIPTION_PLAN_FAILURE',
  SUBSCRIPTION_PLAN_REQUEST: 'SUBSCRIPTION_PLAN_REQUEST',
  SUBSCRIPTION_PLAN_RESET: 'SUBSCRIPTION_PLAN_RESET',
  USER_CARD_CREATE_REQUEST: 'USER_CARD_CREATE_REQUEST',
  USER_CARD_CREATE_SUCCESS: 'USER_CARD_CREATE_SUCCESS',
  USER_CARD_CREATE_FAILURE: 'USER_CARD_CREATE_FAILURE',
  USER_PLAN_SUBSCRIPTION_REQUEST: 'USER_PLAN_SUBSCRIPTION_REQUEST',
  USER_PLAN_SUBSCRIPTION_SUCCESS: 'USER_PLAN_SUBSCRIPTION_SUCCESS',
  USER_PLAN_SUBSCRIPTION_FAILURE: 'USER_PLAN_SUBSCRIPTION_FAILURE',
  FETCH_SECTION_LIST_SUCCESS: 'FETCH_SECTION_LIST_SUCCESS',
  CREATE_JOB_SUCCESS: 'CREATE_JOB_SUCCESS',
  CREATE_JOB_REQUEST: 'CREATE_JOB_REQUEST',
  CREATE_JOB_FAILURE: 'CREATE_JOB_FAILURE',
  FETCH_MORE_SECTION_SUCCESS: 'FETCH_MORE_SECTION_SUCCESS',
  FETCH_SECTION_LIST_FAILURE: 'FETCH_SECTION_LIST_FAILURE',
  FETCH_SECTION_LIST_REQUEST: 'FETCH_SECTION_LIST_REQUEST',
  FETCH_SECTION_DETAIL_SUCCESS: 'FETCH_SECTION_DETAIL_SUCCESS',
  FETCH_SECTION_DETAIL_FAILURE: 'FETCH_SECTION_DETAIL_FAILURE',
  FETCH_SECTION_DETAIL_REQUEST: 'FETCH_SECTION_DETAIL_REQUEST',
  FETCH_SECTION_DETAIL_RESET: 'FETCH_SECTION_DETAIL_RESET',
  FETCH_SECTION_DETAIL_RESET_ERROR: 'FETCH_SECTION_DETAIL_RESET_ERROR',
  FETCH_SECTION_LIST_RESET_ERROR: 'FETCH_SECTION_LIST_RESET_ERROR',
  FETCH_SECTION_LIST_RESET: 'FETCH_SECTION_LIST_RESET',
  SHOW_CLIENT_DEVICE_INITIAL_REQUEST: 'SHOW_CLIENT_DEVICE_INITIAL_REQUEST',
  SHOW_CLIENT_DEVICE_REQUEST: 'SHOW_CLIENT_DEVICE_REQUEST',
  SHOW_CLIENT_DEVICE_SUCCESS: 'SHOW_CLIENT_DEVICE_SUCCESS',
  SHOW_CLIENT_DEVICE_FAILURE: 'SHOW_CLIENT_DEVICE_FAILURE',
  UPLOAD_XLXS_REQUEST: 'UPLOAD_XLXS_REQUEST',
  UPLOAD_XLXS_SUCCESS: 'UPLOAD_XLXS_SUCCESS',
  UPLOAD_XLXS_FAILURE: 'UPLOAD_XLXS_FAILURE',
  CREATE_DEVICE_LIST_REQUEST: 'CRAETE_DEVICE_LIST_REQUEST',
  CREATE_DEVICE_LIST_SUCCESS: 'CREATE_DEVICE_LIST_SUCCESS',
  CREATE_DEVICE_LIST_FAILURE: 'CRAETE_DEVICE_LIST_FAILURE',
  GET_INNER_DEVICE_LIST_REQUEST: 'GET_INNER_DEVICE_LIST_REQUEST',
  GET_INNER_DEVICE_LIST_SUCCESS: 'GET_INNER_DEVICE_LIST_SUCCESS',
  GET_INNER_DEVICE_LIST_FAILURE: 'GET_INNER_DEVICE_LIST_FAILURE',
  GET_INNER_DEVICE_LIST_CLEAR: 'GET_INNER_DEVICE_LIST_CLEAR',
  ADD_DEVICE_REQUEST: 'ADD_DEVICE_REQUEST',
  ADD_DEVICE_INITIAL_REQUEST: 'ADD_DEVICE_INITIAL_REQUEST',
  ADD_DEVICE_SUCCESS: 'ADD_DEVICE_SUCCESS',
  ADD_DEVICE_FAILURE: 'ADD_DEVICE_FAILURE',
  DELETE_DEVICE_REQUEST: 'DELETE_DEVICE_REQUEST',
  DELETE_DEVICE_SUCCESS: 'DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_FAILURE: 'DELETE_DEVICE_FAILURE',
  DELETE_CLIENT_DEVICE_REQUEST: 'DELETE_CLIENT_DEVICE_REQUEST',
  DELETE_CLIENT_DEVICE_SUCCESS: 'DELETE_CLIENT_DEVICE_SUCCESS',
  DELETE_CLIENT_DEVICE_FAILURE: 'DELETE_CLIENT_DEVICE_FAILURE',
  EDIT_DEVICE_REQUEST: 'EDIT_DEVICE_REQUEST',
  EDIT_DEVICE_SUCCESS: 'EDIT_DEVICE_SUCCESS',
  EDIT_DEVICE_FAILURE: 'EDIT_DEVICE_FAILURE',
  SERVICE_REQUEST_FETCH_SUCCESS: 'SERVICE_REQUEST_FETCH_SUCCESS',
  SERVICE_REQUEST_FETCH_ERROR: 'SERVICE_REQUEST_FETCH_ERROR',
  SERVICE_REQUEST_FETCH_REQUEST: 'SERVICE_REQUEST_FETCH_REQUEST',
  SERVICE_REQUEST_FILTER_HEAD_PRESS: 'SERVICE_REQUEST_FILTER_HEAD_PRESS',
  SERVICE_REQUEST_TOGGLE_FILTER: 'SERVICE_REQUEST_TOGGLE_FILTER',
  SERVICE_REQUEST_SEARCH_FIELD_UPDATED: 'SERVICE_REQUEST_SEARCH_FIELD_UPDATED',
  SERVICE_REQUEST_TYPE_UPDATED: 'SERVICE_REQUEST_TYPE_UPDATED',
  SERVICE_REQUEST_RELOAD: 'SERVICE_REQUEST_RELOAD',
  SERVICE_REQUEST_TOGGLE_ORDER: 'SERVICE_REQUEST_TOGGLE_ORDER',
  SERVICE_REQUEST_APPLY_FILTERS: 'SERVICE_REQUEST_APPLY_FILTERS',
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  EMAIL_LIST_FORM_FETCH_EMAILS_REQUEST: 'EMAIL_LIST_FORM_FETCH_EMAILS_REQUEST',
  EMAIL_LIST_FORM_FETCH_EMAILS_SUCCESS: 'EMAIL_LIST_FORM_FETCH_EMAILS_SUCCESS',
  EMAIL_LIST_FORM_FETCH_EMAILS_ERROR: 'EMAIL_LIST_FORM_FETCH_EMAILS_ERROR',
  EMAIL_LIST_FORM_ADD_NEW: 'EMAIL_LIST_FORM_ADD_NEW',
  EMAIL_LIST_FORM_REMOVE_NEW: 'EMAIL_LIST_FORM_REMOVE_NEW',
  EMAIL_LIST_FORM_CLEAR_STATE: 'EMAIL_LIST_FORM_CLEAR_STATE',
  EMAIL_LIST_FORM_SAVE_NEW_SUCCESS: 'EMAIL_LIST_FORM_SAVE_NEW_SUCCESS',
  EMAIL_LIST_FORM_SAVE_NEW_REQUEST: 'EMAIL_LIST_FORM_SAVE_NEW_REQUEST',
  EMAIL_LIST_FORM_SAVE_NEW_ERROR: 'EMAIL_LIST_FORM_SAVE_NEW_ERROR',
  EMAIL_LIST_UPDATE_FIELD: 'EMAIL_LIST_UPDATE_FIELD',
  EMAIL_LIST_SET_ERROR: 'EMAIL_LIST_SET_ERROR',
  EMAIL_LIST_CLEAR_FETCH_API_STATE: 'EMAIL_LIST_CLEAR_FETCH_API_STATE',
  EMAIL_LIST_CLEAR_SAVE_API_STATE: 'EMAIL_LIST_CLEAR_SAVE_API_STATE',
  USER_PLAN_SUBSCRIPTION_CLEAR: 'USER_PLAN_SUBSCRIPTION_CLEAR',
  USER_CARD_CREATE_CLEAR: 'USER_CARD_CREATE_CLEAR',
  UPDATE_COMPANY_LOGO_SUCCESS: 'UPDATE_COMPANY_LOGO_SUCCESS',
  UPDATE_COMPANY_LOGO_FAILURE: 'UPDATE_COMPANY_LOGO_FAILURE',
  UPDATE_COMPANY_LOGO_REQUEST: 'UPDATE_COMPANY_LOGO_REQUEST',
  UPDATE_COMPANY_LOGO_RESET: 'UPDATE_COMPANY_LOGO_RESET',
  GET_SYSTEM_INFO_SUCCESS: 'GET_SYSTEM_INFO_SUCCESS',
  GET_SYSTEM_INFO_REQUEST: 'GET_SYSTEM_INFO_REQUEST',
  GET_SYSTEM_INFO_FAILURE: 'GET_SYSTEM_INFO_FAILURE',
  GET_REPORT_CATEGORY_SUCCESS: 'GET_REPORT_CATEGORY_SUCCESS',
  GET_REPORT_CATEGORY_FAILURE: 'GET_REPORT_CATEGORY_FAILURE',
  GET_REPORT_CATEGORY_REQUEST: 'GET_REPORT_CATEGORY_REQUEST',
  GET_WORKING_REPORT_CATEGORY_SUCCESS: 'GET_WORKING_REPORT_CATEGORY_SUCCESS',
  GET_WORKING_REPORT_CATEGORY_FAILURE: 'GET_WORKING_REPORT_CATEGORY_FAILURE',
  GET_WORKING_REPORT_CATEGORY_REQUEST: 'GET_WORKING_REPORT_CATEGORY_REQUEST',
  SET_WORKING_REPORT_CATEGORY_SUCCESS: 'SET_WORKING_REPORT_CATEGORY_SUCCESS',
  SET_WORKING_REPORT_CATEGORY_FAILURE: 'SET_WORKING_REPORT_CATEGORY_FAILURE',
  SET_WORKING_REPORT_CATEGORY_REQUEST: 'SET_WORKING_REPORT_CATEGORY_REQUEST',
  ADD_SYSTEM_OR_DEVICE_SUCCESS: 'ADD_SYSTEM_OR_DEVICE_SUCCESS',
  ADD_SYSTEM_OR_DEVICE_FAILURE: 'ADD_SYSTEM_OR_DEVICE_FAILURE',
  ADD_SYSTEM_OR_DEVICE_REQUEST: 'ADD_SYSTEM_OR_DEVICE_REQUEST',
  DELETE_SYSTEM_OR_DEVICE_SUCCESS: 'DELETE_SYSTEM_OR_DEVICE_SUCCESS',
  DELETE_SYSTEM_OR_DEVICE_FAILURE: 'DELETE_SYSTEM_OR_DEVICE_FAILURE',
  DELETE_SYSTEM_OR_DEVICE_REQUEST: 'DELETE_SYSTEM_OR_DEVICE_REQUEST',
  UPLOAD_IMAGE_REQUEST: 'UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILURE: 'UPLOAD_IMAGE_FAILURE',
  UPLOAD_IMAGE_RESET: 'UPLOAD_IMAGE_RESET',
  ADD_UPLOADED_IMAGE_DETAILS_REQUEST: 'ADD_UPLOADED_IMAGE_DETAILS_REQUEST',
  ADD_UPLOADED_IMAGE_DETAILS_SUCCESS: 'ADD_UPLOADED_IMAGE_DETAILS_SUCCESS',
  ADD_UPLOADED_IMAGE_DETAILS_FAILURE: 'ADD_UPLOADED_IMAGE_DETAILS_FAILURE',
  FETCH_BUILDING_INFO_REQUEST: 'FETCH_BUILDING_INFO_REQUEST',
  FETCH_BUILDING_INFO_SUCCESS: 'FETCH_BUILDING_INFO_SUCCESS',
  FETCH_BUILDING_INFO_FAILURE: 'FETCH_BUILDING_INFO_FAILURE',
  IMAGE_DESCRIPTION_REQUEST: 'IMAGE_DESCRIPTION_REQUEST',
  IMAGE_DESCRIPTION_SUCCESS: 'IMAGE_DESCRIPTION_SUCCESS',
  IMAGE_DESCRIPTION_FAILURE: 'IMAGE_DESCRIPTION_FAILURE',
  DELETE_IMAGE_REQUEST: 'DELETE_IMAGE_REQUEST',
  DELETE_IMAGE_SUCCESS: 'DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_FAILURE: 'DELETE_IMAGE_FAILURE',
  ADD_NOTES_SUCCESS: 'ADD_NOTES_SUCCESS',
  ADD_NOTES_FAILURE: 'ADD_NOTES_FAILURE',
  ADD_NOTES_REQUEST: 'ADD_NOTES_REQUEST',
  ADD_CUSTOMER_SUCCESS: 'ADD_CUSTOMER_SUCCESS',
  ADD_CUSTOMER_REQUEST: 'ADD_CUSTOMER_REQUEST',
  ADD_CUSTOMER_FAILURE: 'ADD_CUSTOMER_FAILURE',
  UPDATE_CLIENT_SUCCESS: 'UPDATE_CLIENT_SUCCESS',
  UPDATE_CLIENT_REQUEST: 'UPDATE_CLIENT_REQUEST',
  UPDATE_CLIENT_FAILURE: 'UPDATE_CLIENT_FAILURE',
  SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
  SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
  SEND_EMAIL_FAILURE: 'SEND_EMAIL_FAILURE',
  FETCH_EMPLOYESS_DETAIL_SUCCESS: 'FETCH_EMPLOYESS_DETAIL_SUCCESS',
  FETCH_EMPLOYESS_DETAIL_REQUEST: 'FETCH_EMPLOYESS_DETAIL_REQUEST',
  FETCH_EMPLOYESS_DETAIL_FAILURE: 'FETCH_EMPLOYESS_DETAIL_FAILURE',
  FETCH_EMPLOYESS_DETAIL_RESET: 'FETCH_EMPLOYESS_DETAIL_RESET',
  SET_JOB_DETAILS: 'SET_JOB_DETAILS',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  CLEAR_PERMISSIONS: 'CLEAR_PERMISSIONS',
  IMPORT_CUSTOMERS_SUCCESS: 'IMPORT_CUSTOMERS_SUCCESS',
  IMPORT_CUSTOMERS_REQUEST: 'IMPORT_CUSTOMERS_REQUEST',
  IMPORT_CUSTOMERS_FAILURE: 'IMPORT_CUSTOMERS_FAILURE',
  GET_JOB_LINK_REQUEST: 'GET_JOB_LINK_REQUEST',
  GET_JOB_LINK_SUCCESS: 'GET_JOB_LINK_SUCCESS',
  GET_JOB_LINK_FAILURE: 'GET_JOB_LINK_FAILURE',
  GET_NEXT_MONTH_JOBS_REQUEST: 'GET_NEXT_MONTH_JOBS_REQUEST',
  GET_NEXT_MONTH_JOBS_SUCCESS: 'GET_NEXT_MONTH_JOBS_SUCCESS',
  GET_NEXT_MONTH_JOBS_FAILURE: 'GET_NEXT_MONTH_JOBS_FAILURE',
  GET_JOB_LINK_RESET: 'GET_JOB_LINK_RESET',
  FETCH_REPORT_CATEGORY_SUCCESS: 'FETCH_REPORT_CATEGORY_SUCCESS',
  FETCH_REPORT_CATEGORY_FAILURE: 'FETCH_REPORT_CATEGORY_FAILURE',
  FETCH_REPORT_CATEGORY_REQUEST: 'FETCH_REPORT_CATEGORY_REQUEST',
  FETCH_REPORT_CATEGORY_CLEAR: 'FETCH_REPORT_CATEGORY_CLEAR',
  CREATE_REPORT_SUCCESS: 'CREATE_REPORT_SUCCESS',
  CREATE_REPORT_FAILURE: 'CREATE_REPORT_FAILURE',
  CREATE_REPORT_REQUEST: 'CREATE_REPORT_REQUEST',
  CREATE_REPORT_CLEAR: 'CREATE_REPORT_CLEAR',
  SET_REPORT_CAT: 'SET_REPORT_CAT',
  UPDATE_REPORT_FAILURE: 'UPDATE_REPORT_FAILURE',
  UPDATE_REPORT_REQUEST: 'UPDATE_REPORT_REQUEST',
  UPDATE_REPORT_SUCCESS: 'UPDATE_REPORT_SUCCESS',
  UPDATE_REPORT_CLEAR: 'UPDATE_REPORT_CLEAR',
  CLEAR_SUCCESS_REPORT: 'CLEAR_SUCCESS_REPORT',
  RESET_REPORT_LOGO_LIST: 'RESET_REPORT_LOGO_LIST',
  GET_REPORT_PART_LIST_SUCCESS: 'GET_REPORT_PART_LIST_SUCCESS',
  GET_REPORT_PART_LIST_REQUEST: 'GET_REPORT_PART_LIST_REQUEST',
  GET_REPORT_PART_LIST_FAILURE: 'GET_REPORT_PART_LIST_FAILURE',
  RESET_GRID_LIST_REPORT: 'RESET_GRID_LIST_REPORT',
  SET_GEO_LOCATION: 'SET_GEO_LOCATION',
  CLEAR_GEO_LOCATION: 'CLEAR_GEO_LOCATION',
  SET_EMPLOYEE_LOCATION_REQUEST: 'SET_EMPLOYEE_LOCATION_REQUEST',
  SET_EMPLOYEE_LOCATION_SUCCESS: 'SET_EMPLOYEE_LOCATION_SUCCESS',
  SET_EMPLOYEE_LOCATION_FAILURE: 'SET_EMPLOYEE_LOCATION_FAILURE',
  FETCH_GPS_EMPLOYEE_DETAIL_REQUEST: 'FETCH_GPS_EMPLOYEE_DETAIL_REQUEST',
  FETCH_GPS_EMPLOYEE_DETAIL_INITIAL_REQUEST:
    'FETCH_GPS_EMPLOYEE_DETAIL_INITIAL_REQUEST',
  FETCH_GPS_EMPLOYEE_DETAIL_SUCCESS: 'FETCH_GPS_EMPLOYEE_DETAIL_SUCCESS',
  FETCH_GPS_EMPLOYEE_DETAIL_FAILURE: 'FETCH_GPS_EMPLOYEE_DETAIL_FAILURE',
  TIME_SHEET_BY_DATE_REQUEST: 'TIME_SHEET_BY_DATE_REQUEST',
  TIME_SHEET_BY_DATE_SUCCESS: 'TIME_SHEET_BY_DATE_SUCCESS',
  TIME_SHEET_BY_DATE_FAILURE: 'TIME_SHEET_BY_DATE_FAILURE',
  ADD_BREAD_CUMS: 'ADD_BREAD_CUMS',
  CLEAR_BREAD_CUMS: 'CLEAR_BREAD_CUMS',
  SEARCH_GPS_EMPLOYEE_REQUEST: 'SEARCH_GPS_EMPLOYEE_REQUEST',
  SEARCH_GPS_EMPLOYEE_SUCCESS: 'SEARCH_GPS_EMPLOYEE_SUCCESS',
  SEARCH_GPS_EMPLOYEE_FAILURE: 'SEARCH_GPS_EMPLOYEE_FAILURE',
  GET_GENERIC_ACCESS_PERMISSIONS_REQUEST:
    'GET_GENERIC_ACCESS_PERMISSIONS_REQUEST',
  GET_GENERIC_ACCESS_PERMISSIONS_SUCCESS:
    'GET_GENERIC_ACCESS_PERMISSIONS_SUCCESS',
  GET_GENERIC_ACCESS_PERMISSIONS_FAILURE:
    'GET_GENERIC_ACCESS_PERMISSIONS_FAILURE',
  CHAT_SET_ACTIVE_CHAT_ID: 'CHAT_SET_ACTIVE_CHAT_ID',
  CHAT_SEND_MESSAGE: 'CHAT_SEND_MESSAGE',
  CHAT_UPDATE_MESSAGE_BY_ID: 'CHAT_UPDATE_MESSAGE_BY_ID',
  CHAT_FETCH_PEOPLE_LIST_INIT: 'CHAT_FETCH_PEOPLE_LIST_INIT',
  CHAT_FETCH_PEOPLE_LIST_SUCCESS: 'CHAT_FETCH_PEOPLE_LIST_SUCCESS',
  CHAT_FETCH_PEOPLE_LIST_FAILURE: 'CHAT_FETCH_PEOPLE_LIST_FAILURE',
  CHAT_FETCH_MESSAGES_BY_CHATID_INIT: 'CHAT_FETCH_MESSAGES_BY_CHATID_INIT',
  CHAT_FETCH_MESSAGES_BY_CHATID_SUCCESS:
    'CHAT_FETCH_MESSAGES_BY_CHATID_SUCCESS',
  CHAT_FETCH_MESSAGES_BY_CHATID_FAILURE:
    'CHAT_FETCH_MESSAGES_BY_CHATID_FAILURE',
  CHAT_MESSAGE_RECEIVED: 'CHAT_MESSAGE_RECEIVED',
  CHAT_UPDATE_MESSAGES_BY_CHAT_ID: 'CHAT_UPDATE_MESSAGES_BY_CHAT_ID',
  CHAT_GET_ALL_PEOPLE_INIT: 'CHAT_GET_ALL_PEOPLE_INIT',
  CHAT_GET_ALL_PEOPLE_SUCCESS: 'CHAT_GET_ALL_PEOPLE_SUCCESS',
  CHAT_GET_ALL_PEOPLE_FAILURE: 'CHAT_GET_ALL_PEOPLE_FAILURE',
  CHAT_CREATE_INDIVIDUAL_CHAT_INIT: 'CHAT_CREATE_INDIVIDUAL_CHAT_INIT',
  CHAT_CREATE_INDIVIDUAL_CHAT_SUCCESS: 'CHAT_CREATE_INDIVIDUAL_CHAT_SUCCESS',
  CHAT_CREATE_INDIVIDUAL_CHAT_FAILURE: 'CHAT_CREATE_INDIVIDUAL_CHAT_FAILURE',
  CHAT_CREATE_GROUP_CHAT_INIT: 'CHAT_CREATE_GROUP_CHAT_INIT',
  CHAT_CREATE_GROUP_CHAT_SUCCESS: 'CHAT_CREATE_GROUP_CHAT_SUCCESS',
  CHAT_CREATE_GROUP_CHAT_FAILURE: 'CHAT_CREATE_GROUP_CHAT_FAILURE',
  CHAT_GET_ROOM_DETAILS_INIT: 'CHAT_GET_ROOM_DETAILS_INIT',
  CHAT_GET_ROOM_DETAILS_SUCCESS: 'CHAT_GET_ROOM_DETAILS_SUCCESS',
  CHAT_GET_ROOM_DETAILS_FAILURE: 'CHAT_GET_ROOM_DETAILS_FAILURE',
  CHAT_UPDATE_ROOM_DETAILS_INIT: 'CHAT_UPDATE_ROOM_DETAILS_INIT',
  CHAT_UPDATE_ROOM_DETAILS_SUCCESS: 'CHAT_UPDATE_ROOM_DETAILS_SUCCESS',
  CHAT_UPDATE_ROOM_DETAILS_FAILURE: 'CHAT_UPDATE_ROOM_DETAILS_FAILURE',
  CHAT_DELETE_MESSAGE: 'CHAT_DELETE_MESSAGE',
  CHAT_MARK_AS_UNDELIVERED: 'CHAT_MARK_AS_UNDELIVERED',
  GET_JOB_DETAILS_SUCCESS: 'GET_JOB_DETAILS_SUCCESS',
  GET_JOB_DETAILS_REQUEST: 'GET_JOB_DETAILS_REQUEST',
  GET_JOB_DETAILS_FAILURE: 'GET_JOB_DETAILS_FAILURE',
  GET_TEAM_LIST_WITH_FILTERS_SUCCESS: 'GET_TEAM_LIST_WITH_FILTERS_SUCCESS',
  GET_TEAM_LIST_WITH_FILTERS_FAILURE: 'GET_TEAM_LIST_WITH_FILTERS_FAILURE',
  GET_TEAM_LIST_WITH_FILTERS_REQUEST: 'GET_TEAM_LIST_WITH_FILTERS_REQUEST',
  RESET_TEAM_LIST_WITH_FILTERS: 'RESET_TEAM_LIST_WITH_FILTERS',
  SET_CALENDER_DATA: 'SET_CALENDER_DATA',
  CLEAR_CALENDER_DATA: 'CLEAR_CALENDER_DATA',
  ASSIGN_JOB_REQUEST: 'ASSIGN_JOB_REQUEST',
  ASSIGN_JOB_SUCCESS: 'ASSIGN_JOB_SUCCESS',
  ASSIGN_JOB_FAILURE: 'ASSIGN_JOB_FAILURE',
  ADD_REPORT_PARTS: 'ADD_REPORT_PARTS',
  ADD_REPORT_LABOUR: 'ADD_REPORT_LABOUR',
  GET_REPORT_LABOUR_LIST_SUCCESS: 'GET_REPORT_LABOUR_LIST_SUCCESS',
  GET_REPORT_LABOUR_LIST_FAILURE: 'GET_REPORT_LABOUR_LIST_FAILURE',
  GET_REPORT_LABOUR_LIST_REQUEST: 'GET_REPORT_LABOUR_LIST_REQUEST',
  GET_REPORT_PARTS: 'GET_REPORT_PARTS',
  CLEAR_JOB_DETAILS: 'CLEAR_JOB_DETAILS',
  UPDATE_TIME_SHEET_SUCCESS: 'UPDATE_TIME_SHEET_SUCCESS',
  SORT_TEAM_LIST_REQUEST: 'SORT_TEAM_LIST_REQUEST',
  SORT_TEAM_LIST_SUCCESS: 'SORT_TEAM_LIST_SUCCESS',
  SORT_TEAM_LIST_FAILURE: 'SORT_TEAM_LIST_FAILURE',
  SORT_INITIAL_TEAM_REQUEST: 'SORT_INITIAL_TEAM_REQUEST',
  UNASSIGN_JOB_REQUEST: 'UNASSIGN_JOB_REQUEST',
  UNASSIGN_JOB_SUCCESS: 'UNASSIGN_JOB_SUCCESS',
  UNASSIGN_JOB_FAILURE: 'UNASSIGN_JOB_FAILURE',
  DELETE_JOB_REQUEST: 'DELETE_JOB_REQUEST',
  DELETE_JOB_SUCCESS: 'DELETE_JOB_SUCCESS',
  DELETE_JOB_FAILURE: 'DELETE_JOB_FAILURE',
  GET_LAT_LONG_WITH_ADDRESS_SUCCESS: 'GET_LAT_LONG_WITH_ADDRESS_SUCCESS',
  GET_LAT_LONG_WITH_ADDRESS_FAILURE: 'GET_LAT_LONG_WITH_ADDRESS_FAILURE',
  GET_LAT_LONG_WITH_ADDRESS_REQUEST: 'GET_LAT_LONG_WITH_ADDRESS_REQUEST',
  /* INVENTORY */
  INVENTORY_ADD_SUPPLIER_REQUEST: 'INVENTORY_ADD_SUPPLIER_REQUEST',
  INVENTORY_ADD_SUPPLIER_FAILURE: 'INVENTORY_ADD_SUPPLIER_FAILURE',
  INVENTORY_ADD_SUPPLIER_SUCCESS: 'INVENTORY_ADD_SUPPLIER_SUCCESS',
  INVENTORY_ADD_SUPPLIER_RESET: 'INVENTORY_ADD_SUPPLIER_RESET',

  INVENTORY_EDIT_SUPPLIER_REQUEST: 'INVENTORY_EDIT_SUPPLIER_REQUEST',
  INVENTORY_EDIT_SUPPLIER_FAILURE: 'INVENTORY_EDIT_SUPPLIER_FAILURE',
  INVENTORY_EDIT_SUPPLIER_SUCCESS: 'INVENTORY_EDIT_SUPPLIER_SUCCESS',
  INVENTORY_EDIT_SUPPLIER_RESET: 'INVENTORY_EDIT_SUPPLIER_RESET',
  INVENTORY_EDIT_SUPPLIER_CLEAR: 'INVENTORY_EDIT_SUPPLIER_CLEAR',

  INVENTORY_GET_SUPPLIER_LIST_REQUEST: 'INVENTORY_GET_SUPPLIER_LIST_REQUEST',
  INVENTORY_GET_SUPPLIER_LIST_FAILURE: 'INVENTORY_GET_SUPPLIER_LIST_FAILURE',
  INVENTORY_GET_SUPPLIER_LIST_SUCCESS: 'INVENTORY_GET_SUPPLIER_LIST_SUCCESS',
  INVENTORY_GET_SUPPLIER_LIST_RESET: 'INVENTORY_GET_SUPPLIER_LIST_RESET',

  INVENTORY_GET_VEHICLE_LIST_REQUEST: 'INVENTORY_GET_VEHICLE_LIST_REQUEST',
  INVENTORY_GET_VEHICLE_LIST_FAILURE: 'INVENTORY_GET_VEHICLE_LIST_FAILURE',
  INVENTORY_GET_VEHICLE_LIST_SUCCESS: 'INVENTORY_GET_VEHICLE_LIST_SUCCESS',
  INVENTORY_GET_VEHICLE_LIST_RESET: 'INVENTORY_GET_VEHICLE_LIST_RESET',

  INVENTORY_DELETE_VEHICLE_DETAILS_REQUEST:
    'INVENTORY_DELETE_VEHICLE_DETAILS_REQUEST',
  INVENTORY_DELETE_VEHICLE_DETAILS_FAILURE:
    'INVENTORY_DELETE_VEHICLE_DETAILS_FAILURE',
  INVENTORY_DELETE_VEHICLE_DETAILS_SUCCESS:
    'INVENTORY_DELETE_VEHICLE_DETAILS_SUCCESS',
  INVENTORY_DELETE_VEHICLE_DETAILS_RESET:
    'INVENTORY_DELETE_VEHICLE_DETAILS_RESET',

  UPLOADED_PARTS_DETAILS_SUCCESS: 'UPLOADED_PARTS_DETAILS_SUCCESS',
  UPLOADED_PARTS_DETAILS_FAILURE: 'UPLOADED_PARTS_DETAILS_FAILURE',
  UPLOADED_PARTS_DETAILS_REQUEST: 'UPLOADED_PARTS_DETAILS_REQUEST',
  UPLOADED_PARTS_DETAILS_RESET: 'UPLOADED_PARTS_DETAILS_RESET',

  GET_SUPPLIER_DETAILS_SUCCESS: 'GET_SUPPLIER_DETAILS_SUCCESS',
  GET_SUPPLIER_DETAILS_FAILURE: 'GET_SUPPLIER_DETAILS_FAILURE',
  GET_SUPPLIER_DETAILS_REQUEST: 'GET_SUPPLIER_DETAILS_REQUEST',
  GET_SUPPLIER_DETAILS_RESET: 'GET_SUPPLIER_DETAILS_RESET',

  INVENTORY_GET_PARTS_LIST_REQUEST: 'INVENTORY_GET_PARTS_LIST_REQUEST',
  INVENTORY_GET_PARTS_LIST_FAILURE: 'INVENTORY_GET_PARTS_LIST_FAILURE',
  INVENTORY_GET_PARTS_LIST_SUCCESS: 'INVENTORY_GET_PARTS_LIST_SUCCESS',
  INVENTORY_GET_PARTS_LIST_RESET: 'INVENTORY_GET_PARTS_LIST_RESET',

  INVENTORY_ASSIGN_PARTS_REQUEST: 'INVENTORY_ASSIGN_PARTS_REQUEST',
  INVENTORY_ASSIGN_PARTS_FAILURE: 'INVENTORY_ASSIGN_PARTS_FAILURE',
  INVENTORY_ASSIGN_PARTS_SUCCESS: 'INVENTORY_ASSIGN_PARTS_SUCCESS',
  INVENTORY_ASSIGN_PARTS_RESET: 'INVENTORY_ASSIGN_PARTS_RESET',

  INVENTORY_PARTS_FILTER_HEAD_PRESS: 'INVENTORY_PARTS_FILTER_HEAD_PRESS',
  INVENTORY_PARTS_TOGGLE_FILTER: 'INVENTORY_PARTS_TOGGLE_FILTER',
  INVENTORY_PARTS_SEARCH_FIELD_UPDATED: 'INVENTORY_PARTS_SEARCH_FIELD_UPDATED',
  INVENTORY_PARTS_RELOAD: 'INVENTORY_PARTS_RELOAD',

  INVENTORY_GET_TOOLS_LIST_REQUEST: 'INVENTORY_GET_TOOLS_LIST_REQUEST',
  INVENTORY_GET_TOOLS_LIST_FAILURE: 'INVENTORY_GET_TOOLS_LIST_FAILURE',
  INVENTORY_GET_TOOLS_LIST_SUCCESS: 'INVENTORY_GET_TOOLS_LIST_SUCCESS',
  INVENTORY_GET_TOOLS_LIST_RESET: 'INVENTORY_GET_TOOLS_LIST_RESET',

  INVENTORY_PARTS_EDIT_MODE: 'INVENTORY_PARTS_EDIT_MODE',
  REMOVE_PART_IMAGE: 'REMOVE_PART_IMAGE',

  INVENTORY_GET_PART_INFO_SUCCESS: 'INVENTORY_GET_PART_INFO_SUCCESS',
  INVENTORY_GET_PART_INFO_FAILURE: 'INVENTORY_GET_PART_INFO_FAILURE',
  INVENTORY_GET_PART_INFO_REQUEST: 'INVENTORY_GET_PART_INFO_REQUEST',
  INVENTORY_GET_PART_INFO_RESET: 'INVENTORY_GET_PART_INFO_RESET',

  INVENTORY_ADD_PART_INFO_REQUEST: 'INVENTORY_ADD_PART_INFO_REQUEST',
  INVENTORY_ADD_PART_INFO_FAILURE: 'INVENTORY_ADD_PART_INFO_FAILURE',
  INVENTORY_ADD_PART_INFO_SUCCESS: 'INVENTORY_ADD_PART_INFO_SUCCESS',
  INVENTORY_ADD_PART_INFO_RESET: 'INVENTORY_ADD_PART_INFO_RESET',

  INVENTORY_EDIT_PART_INFO_REQUEST: 'INVENTORY_EDIT_PART_INFO_REQUEST',
  INVENTORY_EDIT_PART_INFO_FAILURE: 'INVENTORY_EDIT_PART_INFO_FAILURE',
  INVENTORY_EDIT_PART_INFO_SUCCESS: 'INVENTORY_EDIT_PART_INFO_SUCCESS',
  INVENTORY_EDIT_PART_INFO_RESET: 'INVENTORY_EDIT_PART_INFO_RESET',

  INVENTORY_DELETE_PART_INFO_REQUEST: 'INVENTORY_DELETE_PART_INFO_REQUEST',
  INVENTORY_DELETE_PART_INFO_FAILURE: 'INVENTORY_DELETE_PART_INFO_FAILURE',
  INVENTORY_DELETE_PART_INFO_SUCCESS: 'INVENTORY_DELETE_PART_INFO_SUCCESS',
  INVENTORY_DELETE_PART_INFO_RESET: 'INVENTORY_DELETE_PART_INFO_RESET',

  INVENTORY_ADD_PRICE_LEVEL_REQUEST: 'INVENTORY_ADD_PRICE_LEVEL_REQUEST',
  INVENTORY_ADD_PRICE_LEVEL_FAILURE: 'INVENTORY_ADD_PRICE_LEVEL_FAILURE',
  INVENTORY_ADD_PRICE_LEVEL_SUCCESS: 'INVENTORY_ADD_PRICE_LEVEL_SUCCESS',
  INVENTORY_ADD_PRICE_LEVEL_RESET: 'INVENTORY_ADD_PRICE_LEVEL_RESET',

  INVENTORY_GET_PRICE_LEVEL_REQUEST: 'INVENTORY_GET_PRICE_LEVEL_REQUEST',
  INVENTORY_GET_PRICE_LEVEL_FAILURE: 'INVENTORY_GET_PRICE_LEVEL_FAILURE',
  INVENTORY_GET_PRICE_LEVEL_SUCCESS: 'INVENTORY_GET_PRICE_LEVEL_SUCCESS',
  INVENTORY_GET_PRICE_LEVEL_RESET: 'INVENTORY_GET_PRICE_LEVEL_RESET',

  INVENTORY_EDIT_PRICE_LEVEL_REQUEST: 'INVENTORY_EDIT_PRICE_LEVEL_REQUEST',
  INVENTORY_EDIT_PRICE_LEVEL_FAILURE: 'INVENTORY_EDIT_PRICE_LEVEL_FAILURE',
  INVENTORY_EDIT_PRICE_LEVEL_SUCCESS: 'INVENTORY_EDIT_PRICE_LEVEL_SUCCESS',
  INVENTORY_EDIT_PRICE_LEVEL_RESET: 'INVENTORY_EDIT_PRICE_LEVEL_RESET',

  INVENTORY_ADD_VEHICLE_REQUEST: 'INVENTORY_ADD_VEHICLE_REQUEST',
  INVENTORY_ADD_VEHICLE_FAILURE: 'INVENTORY_ADD_VEHICLE_FAILURE',
  INVENTORY_ADD_VEHICLE_SUCCESS: 'INVENTORY_ADD_VEHICLE_SUCCESS',
  INVENTORY_ADD_VEHICLE_RESET: 'INVENTORY_ADD_VEHICLE_RESET',

  INVENTORY_VEHICLE_ASSIGNED_LIST_REQUEST:
    'INVENTORY_VEHICLE_ASSIGNED_LIST_REQUEST',
  INVENTORY_VEHICLE_ASSIGNED_LIST_FAILURE:
    'INVENTORY_VEHICLE_ASSIGNED_LIST_FAILURE',
  INVENTORY_VEHICLE_ASSIGNED_LIST_SUCCESS:
    'INVENTORY_VEHICLE_ASSIGNED_LIST_SUCCESS',
  INVENTORY_VEHICLE_ASSIGNED_LIST_RESET:
    'INVENTORY_VEHICLE_ASSIGNED_LIST_RESET',

  INVENTORY_VEHICLE_UNASSIGNED_ITEM_REQUEST:
    'INVENTORY_VEHICLE_UNASSIGNED_ITEM_REQUEST',
  INVENTORY_VEHICLE_UNASSIGNED_ITEM_FAILURE:
    'INVENTORY_VEHICLE_UNASSIGNED_ITEM_FAILURE',
  INVENTORY_VEHICLE_UNASSIGNED_ITEM_SUCCESS:
    'INVENTORY_VEHICLE_UNASSIGNED_ITEM_SUCCESS',
  INVENTORY_VEHICLE_UNASSIGNED_ITEM_RESET:
    'INVENTORY_VEHICLE_UNASSIGNED_ITEM_RESET',

  FETCH_ASSIGNED_TECHNICIAN_REQUEST: 'FETCH_ASSIGNED_TECHNICIAN_REQUEST',
  FETCH_ASSIGNED_TECHNICIAN_FAILURE: 'FETCH_ASSIGNED_TECHNICIAN_FAILURE',
  FETCH_ASSIGNED_TECHNICIAN_SUCCESS: 'FETCH_ASSIGNED_TECHNICIAN_SUCCESS',
  FETCH_ASSIGNED_TECHNICIAN_RESET: 'FETCH_ASSIGNED_TECHNICIAN_RESET',

  INVENTORY_GET_SUPPLIER_LIST_UPDATE: 'INVENTORY_GET_SUPPLIER_LIST_UPDATE',
  GET_REPORT_LIST_SUCCESS: 'GET_REPORT_LIST_SUCCESS',
  GET_REPORT_LIST_REQUEST: 'GET_REPORT_LIST_REQUEST',
  GET_REPORT_LIST_FAILURE: 'GET_REPORT_LIST_FAILURE',
  GET_VIEW_REPORT_LIST_SUCCESS: 'GET_VIEW_REPORT_LIST_SUCCESS',
  GET_VIEW_REPORT_LIST_REQUEST: 'GET_VIEW_REPORT_LIST_REQUEST',
  GET_VIEW_REPORT_LIST_FAILURE: 'GET_VIEW_REPORT_LIST_FAILURE',
  DELETE_REPORT_LIST_SUCCESS: 'DELETE_REPORT_LIST_SUCCESS',
  DELETE_REPORT_LIST_FAILURE: 'DELETE_REPORT_LIST_FAILURE',
  DELETE_REPORT_LIST_REQUEST: 'DELETE_REPORT_LIST_REQUEST',
  CLEAR_REPORT_DELETE_STATE: 'CLEAR_REPORT_DELETE_STATE',
  FETCH_REPORT_DETAIL_RESET: 'FETCH_REPORT_DETAIL_RESET',
  FETCH_REPORT_DETAIL_FAILURE: 'FETCH_REPORT_DETAIL_FAILURE',
  FETCH_REPORT_DETAIL_SUCCESS: 'FETCH_REPORT_DETAIL_SUCCESS',
  FETCH_REPORT_DETAIL_REQUEST: 'FETCH_REPORT_DETAIL_REQUEST',
  FETCH_REPORT_DETAIL_SUCCESS_RESET: 'FETCH_REPORT_DETAIL_SUCCESS_RESET',

  // inventory tools
  GET_TOOLS_LIST_SUCCESS: 'GET_TOOLS_LIST_SUCCESS',
  GET_TOOLS_LIST_FAILURE: 'GET_TOOLS_LIST_FAILURE',
  GET_TOOLS_LIST_REQUEST: 'GET_TOOLS_LIST_REQUEST',
  GET_TOOLS_LIST_RESET: 'GET_TOOLS_LIST_RESET',
  ADD_RECURRING_INSPECTION_REQUEST: 'ADD_RECURRING_INSPECTION_REQUEST',
  ADD_RECURRING_INSPECTION_SUCCESS: 'ADD_RECURRING_INSPECTION_SUCCESS',
  ADD_RECURRING_INSPECTION_FAILURE: 'ADD_RECURRING_INSPECTION_FAILURE',
  FETCH_RECURRING_INSPECTION_REQUEST: 'FETCH_RECURRING_INSPECTION_REQUEST',
  FETCH_RECURRING_INSPECTION_INITIAL_REQUEST:
    'FETCH_RECURRING_INSPECTION_INITIAL_REQUEST',
  FETCH_RECURRING_INSPECTION_SUCCESS: 'FETCH_RECURRING_INSPECTION_SUCCESS',
  FETCH_RECURRING_INSPECTION_FAILURE: 'FETCH_RECURRING_INSPECTION_FAILURE',
  CHAT_UPDATE_ROOM_NAME: 'CHAT_UPDATE_ROOM_NAME',
  DELETE_REPORT_REQUEST: 'DELETE_REPORT_REQUEST',
  DELETE_REPORT_SUCCESS: 'DELETE_REPORT_SUCCESS',
  DELETE_REPORT_FAILURE: 'DELETE_REPORT_FAILURE',
  GET_INSPECTIONS_DUE_LIST_SUCCESS: 'GET_INSPECTIONS_DUE_LIST_SUCCESS',
  GET_INSPECTIONS_DUE_LIST_REQUEST: 'GET_INSPECTIONS_DUE_LIST_REQUEST',
  GET_INSPECTIONS_DUE_LIST_FAILURE: 'GET_INSPECTIONS_DUE_LIST_FAILURE',
  GET_INSPECTIONS_DONE_LIST_FAILURE: 'GET_INSPECTIONS_DONE_LIST_FAILURE',
  GET_INSPECTIONS_DONE_LIST_REQUEST: 'GET_INSPECTIONS_DONE_LIST_REQUEST',
  GET_INSPECTIONS_DONE_LIST_SUCCESS: 'GET_INSPECTIONS_DONE_LIST_SUCCESS',
  GET_INSPECTIONS_COUNT_LIST_SUCCESS: 'GET_INSPECTIONS_COUNT_LIST_SUCCESS',
  GET_INSPECTIONS_COUNT_LIST_REQUEST: 'GET_INSPECTIONS_COUNT_LIST_REQUEST',
  GET_INSPECTIONS_COUNT_LIST_FAILURE: 'GET_INSPECTIONS_COUNT_LIST_FAILURE',
  GET_INSPECTIONS_JOBS_LIST_SUCCESS: 'GET_INSPECTIONS_JOBS_LIST_SUCCESS',
  GET_INSPECTIONS_JOBS_LIST_REQUEST: 'GET_INSPECTIONS_JOBS_LIST_REQUEST',
  GET_INSPECTIONS_JOBS_LIST_FAILURE: 'GET_INSPECTIONS_JOBS_LIST_FAILURE',
  GET_PRICING_LEVEL_SUCCESS: 'GET_PRICING_LEVEL_SUCCESS',
  GET_PRICING_LEVEL_REQUEST: 'GET_PRICING_LEVEL_REQUEST',
  GET_PRICING_LEVEL_FAILURE: 'GET_PRICING_LEVEL_FAILURE',
  GET_CLIENT_LIST_FOR_TYPE_CHANGE_SUCCESS:
    'GET_CLIENT_LIST_FOR_TYPE_CHANGE_SUCCESS',
  GET_CLIENT_LIST_FOR_TYPE_CHANGE_FAILURE:
    'GET_CLIENT_LIST_FOR_TYPE_CHANGE_FAILURE',
  GET_CLIENT_LIST_FOR_TYPE_CHANGE_REQUEST:
    'GET_CLIENT_LIST_FOR_TYPE_CHANGE_REQUEST',
  CHANGE_CLIENT_ACCOUNT_TYPE_SUCCESS: 'CHANGE_CLIENT_ACCOUNT_TYPE_SUCCESS',
  CHANGE_CLIENT_ACCOUNT_TYPE_REQUEST: 'CHANGE_CLIENT_ACCOUNT_TYPE_REQUEST',
  CHANGE_CLIENT_ACCOUNT_TYPE_FAILURE: 'CHANGE_CLIENT_ACCOUNT_TYPE_FAILURE',
  GET_XLXS_COUNT_REQUEST: 'GET_XLXS_COUNT_REQUEST',
  GET_XLXS_COUNT_SUCCESS: 'GET_XLXS_COUNT_SUCCESS',
  GET_XLXS_COUNT_FAILURE: 'GET_XLXS_COUNT_FAILURE',
  INVENTORY_DELETE_SUPPLIER_LIST_SUCCESS:
    'INVENTORY_DELETE_SUPPLIER_LIST_SUCCESS',
  INVENTORY_DELETE_SUPPLIER_LIST_FAILURE:
    'INVENTORY_DELETE_SUPPLIER_LIST_FAILURE',
  INVENTORY_DELETE_SUPPLIER_LIST_REQUEST:
    'INVENTORY_DELETE_SUPPLIER_LIST_REQUEST',
  INVENTORY_DELETE_SUPPLIER_LIST_RESET: 'INVENTORY_DELETE_SUPPLIER_LIST_RESET',
  FETCH_REPORT_TEMPLATES_SUCCESS: 'FETCH_REPORT_TEMPLATES_SUCCESS',
  FETCH_REPORT_TEMPLATES_FAILURE: 'FETCH_REPORT_TEMPLATES_FAILURE',
  FETCH_REPORT_TEMPLATES_REQUEST: 'FETCH_REPORT_TEMPLATES_REQUEST',
  ADD_TOOL_SUCCESS: 'ADD_TOOL_SUCCESS',
  ADD_TOOL_FAILURE: 'ADD_TOOL_FAILURE',
  ADD_TOOL_REQUEST: 'ADD_TOOL_REQUEST',
  ADD_TOOL_RESET: 'ADD_TOOL_RESET',
  GET_TOOL_SUCCESS: 'GET_TOOL_SUCCESS',
  GET_TOOL_FAILURE: 'GET_TOOL_FAILURE',
  GET_TOOL_REQUEST: 'GET_TOOL_REQUEST',
  GET_TOOL_RESET: 'GET_TOOL_RESET',
  UPDATE_TOOL_SUCCESS: 'UPDATE_TOOL_SUCCESS',
  UPDATE_TOOL_FAILURE: 'UPDATE_TOOL_FAILURE',
  UPDATE_TOOL_REQUEST: 'UPDATE_TOOL_REQUEST',
  UPDATE_TOOL_RESET: 'UPDATE_TOOL_RESET',
  INVENTORY_ASSIGN_TOOLS_SUCCESS: 'INVENTORY_ASSIGN_TOOLS_SUCCESS',
  INVENTORY_ASSIGN_TOOLS_FAILURE: 'INVENTORY_ASSIGN_TOOLS_FAILURE',
  INVENTORY_ASSIGN_TOOLS_REQUEST: 'INVENTORY_ASSIGN_TOOLS_REQUEST',
  INVENTORY_ASSIGN_TOOLS_RESET: 'INVENTORY_ASSIGN_TOOLS_RESET',
  GET_WAY_POINT_REQUEST: 'GET_WAY_POINT_REQUEST',
  GET_WAY_POINT_SUCCESS: 'GET_WAY_POINT_SUCCESS',
  GET_WAY_POINT_FAILURE: 'GET_WAY_POINT_FAILURE',
  GET_WAY_POINT_DISTANCE_REQUEST: 'GET_WAY_POINT_DISTANCE_REQUEST',
  GET_WAY_POINT_DISTANCE_SUCCESS: 'GET_WAY_POINT_DISTANCE_SUCCESS',
  GET_WAY_POINT_DISTANCE_FAILURE: 'GET_WAY_POINT_DISTANCE_FAILURE',
  AUTO_ROUTING_REQUEST: 'AUTO_ROUTING_REQUEST',
  AUTO_ROUTING_SUCCESS: 'AUTO_ROUTING_SUCCESS',
  AUTO_ROUTING_FAILURE: 'AUTO_ROUTING_FAILURE',
  EDIT_PROFILE_REQUEST: 'EDIT_PROFILE_REQUEST',
  EDIT_PROFILE_SUCCESS: 'EDIT_PROFILE_SUCCESS',
  EDIT_PROFILE_FAILURE: 'EDIT_PROFILE_FAILURE',
  EMAIL_LIST_FORM_DELETE_NEW_SUCCESS: 'EMAIL_LIST_FORM_DELETE_NEW_SUCCESS',
  EMAIL_LIST_FORM_DELETE_NEW_ERROR: 'EMAIL_LIST_FORM_DELETE_NEW_ERROR',
  EMAIL_LIST_FORM_DELETE_NEW_REQUEST: 'EMAIL_LIST_FORM_DELETE_NEW_REQUEST',
  EMAIL_LIST_CLEAR_DELETE_API_STATE: 'EMAIL_LIST_CLEAR_DELETE_API_STATE',
  DELETE_CLIENT_SUCCESS: 'DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_FAILURE: 'DELETE_CLIENT_FAILURE',
  DELETE_CLIENT_REQUEST: 'DELETE_CLIENT_REQUEST',
  DELETE_COMPANY_LOGO_SUCCESS: 'DELETE_COMPANY_LOGO_SUCCESS',
  DELETE_COMPANY_LOGO_FAILURE: 'DELETE_COMPANY_LOGO_FAILURE',
  DELETE_COMPANY_LOGO_REQUEST: 'DELETE_COMPANY_LOGO_REQUEST',
  SET_SELECTED_CUSTOMER_BREADCRUM: 'SET_SELECTED_CUSTOMER_BREADCRUM',
  RESET_SELECTED_CUSTOMER_BREADCRUM: 'RESET_SELECTED_CUSTOMER_BREADCRUM',

  UPDATE_JOB_REQUEST: 'UPDATE_JOB_REQUEST',
  UPDATE_JOB_SUCCESS: 'UPDATE_JOB_SUCCESS',
  UPDATE_JOB_FAILURE: 'UPDATE_JOB_FAILURE',

  UPDATE__VEHICLE_SUCCESS: 'UPDATE__VEHICLE_SUCCESS',
  UPDATE__VEHICLE_FAILURE: 'UPDATE__VEHICLE_FAILURE',
  UPDATE__VEHICLE_REQUEST: 'UPDATE__VEHICLE_REQUEST',
  UPDATE__VEHICLE_RESET: 'UPDATE__VEHICLE_RESET',
  CLEAR_LOGIN_SUCCESS: 'CLEAR_LOGIN_SUCCESS',

  DELETE_INVENTORY_TOOL_SUCCESS: 'DELETE_INVENTORY_TOOL_SUCCESS',
  DELETE_INVENTORY_TOOL_FAILURE: 'DELETE_INVENTORY_TOOL_FAILURE',
  DELETE_INVENTORY_TOOL_REQUEST: 'DELETE_INVENTORY_TOOL_REQUEST',
  DELETE_INVENTORY_TOOL_RESET: 'DELETE_INVENTORY_TOOL_RESET',

  OFFLINE_MODE_ON: 'OFFLINE_MODE_ON',
  RESET_FETCH_JOB_LIST: 'RESET_FETCH_JOB_LIST',
  SET_OFFLINE_SELECTED_JOB_LIST: 'SET_OFFLINE_SELECTED_JOB_LIST',
  RESET_OFFLINE_SELECTED_JOB_LIST: 'RESET_OFFLINE_SELECTED_JOB_LIST',
  CLEAR_GLOBAL_STATE_BOOLS: 'CLEAR_GLOBAL_STATE_BOOLS',

  DELETE_RECURRING_INSPECTION_REQUEST: 'DELETE_RECURRING_INSPECTION_REQUEST',
  DELETE_RECURRING_INSPECTION_SUCCESS: 'DELETE_RECURRING_INSPECTION_SUCCESS',
  DELETE_RECURRING_INSPECTION_FAILURE: 'DELETE_RECURRING_INSPECTION_FAILURE',

  RESET_GLOBAL_STATE: 'RESET_GLOBAL_STATE',
  UPDATE_GLOBAL_DATABASE: 'UPDATE_GLOBAL_DATABASE',
  GLOBAL_OFFLINE_DATABASE_REQUEST: 'GLOBAL_OFFLINE_DATABASE_REQUEST',
  GLOBAL_OFFLINE_DATABASE_SUCCESS: 'GLOBAL_OFFLINE_DATABASE_SUCCESS',
  GLOBAL_OFFLINE_DATABASE_FAILURE: 'GLOBAL_OFFLINE_DATABASE_FAILURE',
  GLOBAL_OFFLINE_TEAM_MEMBER_SUCCESS: 'GLOBAL_OFFLINE_TEAM_MEMBER_SUCCESS',
  GLOBAL_OFFLINE_INVENTORY_PARTS_SUCCESS:
    'GLOBAL_OFFLINE_INVENTORY_PARTS_SUCCESS',

  FETCH_RECURRING_INSPECTION_DETAIL_REQUEST:
    'FETCH_RECURRING_INSPECTION_DETAIL_REQUEST',
  FETCH_RECURRING_INSPECTION_DETAIL_SUCCESS:
    'FETCH_RECURRING_INSPECTION_DETAIL_SUCCESS',
  FETCH_RECURRING_INSPECTION_DETAIL_FAILURE:
    'FETCH_RECURRING_INSPECTION_DETAIL_FAILURE',

  UPDATE_RECURRING_INSPECTION_REQUEST: 'UPDATE_RECURRING_INSPECTION_REQUEST',
  UPDATE_RECURRING_INSPECTION_SUCCESS: 'UPDATE_RECURRING_INSPECTION_SUCCESS',
  UPDATE_RECURRING_INSPECTION_FAILURE: 'UPDATE_RECURRING_INSPECTION_FAILURE',

  GET_REPORT_TEMPLATE_LIST_REQUEST: 'GET_REPORT_TEMPLATE_LIST_REQUEST',
  GET_REPORT_TEMPLATE_LIST_SUCCESS: 'GET_REPORT_TEMPLATE_LIST_SUCCESS',
  GET_REPORT_TEMPLATE_LIST_FAILURE: 'GET_REPORT_TEMPLATE_LIST_FAILURE',
  SET_INSPECTION_ANSWERS: 'SET_INSPECTION_ANSWERS',
  SET_INSPECTION_ARRAY_ANSWERS: 'SET_INSPECTION_ARRAY_ANSWERS',
  UPDATE_ANSWER_SUCCESS: 'UPDATE_ANSWER_SUCCESS',
  UPDATE_ANSWER_REQUEST: 'UPDATE_ANSWER_REQUEST',
  UPDATE_ANSWER_FAILURE: 'UPDATE_ANSWER_FAILURE',
  SET_INSPECTION_FETCH_ANSWERS: 'SET_INSPECTION_FETCH_ANSWERS',
  CLEAR_ANSWERS: 'CLEAR_ANSWERS',
  REPLACE_INSPECTION_ARRAY_ANSWERS: 'REPLACE_INSPECTION_ARRAY_ANSWERS',
  DOWNLOAD_CUSTOMER_EMPLOYEE_LIST_REQUEST:
    'DOWNLOAD_CUSTOMER_EMPLOYEE_LIST_REQUEST',
  DOWNLOAD_CUSTOMER_EMPLOYEE_LIST_SUCCESS:
    'DOWNLOAD_CUSTOMER_EMPLOYEE_LIST_SUCCESS',
  DOWNLOAD_CUSTOMER_EMPLOYEE_LIST_FAILURE:
    'DOWNLOAD_CUSTOMER_EMPLOYEE_LIST_FAILURE',

  SET_LOGIN_USER_DETAILS: 'SET_LOGIN_USER_DETAILS',

  TOGGLE_COACKMARKS_VISIBILITY: 'TOGGLE_COACKMARKS_VISIBILITY',
  RUN_COACHMARKS: 'RUN_COACHMARKS',
  CLEAR_INSPECTION_ANSWER_SUCCESS: 'CLEAR_INSPECTION_ANSWER_SUCCESS',
  REPLACE_INSPECTION_ARRAY_ANSWERS_PARTICULAR_INDEX:
    'REPLACE_INSPECTION_ARRAY_ANSWERS_PARTICULAR_INDEX',
  DELETE_DEVICE_FROM_PARTICULAR_INDEX: 'DELETE_DEVICE_FROM_PARTICULAR_INDEX',
  UPDATE_SWITCHES_INSPECTION: 'UPDATE_SWITCHES_INSPECTION',
  SET_INSPECTION_ANSWERS_INDEX: 'SET_INSPECTION_ANSWERS_INDEX',
  CLEAR_REPORT_TEMPLATE_SUCCESS_STATE: 'CLEAR_REPORT_TEMPLATE_SUCCESS_STATE',
  RESET_UPDATE_INSPECTION_ARRAY_INITIAL_STATE:
    'RESET_UPDATE_INSPECTION_ARRAY_INITIAL_STATE',
  SET_INSPECTION_VIEW_ANSWERS_AND_JSON: 'SET_INSPECTION_VIEW_ANSWERS_AND_JSON',

  UPDATE_EMPLOYEE_PERMISSIONS: 'UPDATE_EMPLOYEE_PERMISSIONS',
  UPDATE_EMPLOYEE_SUBSCRIPTIONS: 'UPDATE_EMPLOYEE_SUBSCRIPTIONS',

  UPDATE_PERMISSIONS_SUCCESS: 'UPDATE_PERMISSIONS_SUCCESS',
  UPDATE_PERMISSIONS_FAILURE: 'UPDATE_PERMISSIONS_FAILURE',
  UPDATE_PERMISSIONS_REQUEST: 'UPDATE_PERMISSIONS_REQUEST',

  UPDATE_SUBSCRIPTIONS_SUCCESS: 'UPDATE_SUBSCRIPTIONS_SUCCESS',
  UPDATE_SUBSCRIPTIONS_FAILURE: 'UPDATE_SUBSCRIPTIONS_FAILURE',
  UPDATE_SUBSCRIPTIONS_REQUEST: 'UPDATE_SUBSCRIPTIONS_REQUEST',

  FETCH_SUBSCRIPTIONS: 'FETCH_SUBSCRIPTIONS',

  DELETE_SUBSCRIPTION_REQUEST: 'DELETE_SUBSCRIPTION_REQUEST',
  DELETE_SUBSCRIPTION_FAILURE: 'DELETE_SUBSCRIPTION_FAILURE',
  DELETE_SUBSCRIPTION_SUCCESS: 'DELETE_SUBSCRIPTION_SUCCESS',
  UPDATE_REPORT_FINISH_REPORT: 'UPDATE_REPORT_FINISH_REPORT',
  UPDATE_SOCKET_ANSWER_INSPECTION: 'UPDATE_SOCKET_ANSWER_INSPECTION',

  GET_MISSED_JOB_LIST_SUCCESS: 'GET_MISSED_JOB_LIST_SUCCESS',
  GET_MISSED_JOB_LIST_FAILURE: 'GET_MISSED_JOB_LIST_FAILURE',
  GET_MISSED_JOB_LIST_REQUEST: 'GET_MISSED_JOB_LIST_REQUEST',

  TOGGLE_OFFLINE_MODE: 'TOGGLE_OFFLINE_MODE',
  TOGGLE_OFFLINE_MODE_MODAL_VISIBILITY: 'TOGGLE_OFFLINE_MODE_MODAL_VISIBILITY',
  SELECT_OFFLINE_MODE_OPTION: 'SELECT_OFFLINE_MODE_OPTION',
  SET_CUSTOMER_MODAL_STATE: 'SET_CUSTOMER_MODAL_STATE',
  TOGGLE_CUSTOMER_MODAL_VISIBILITY: 'TOGGLE_CUSTOMER_MODAL_VISIBILITY',
  ADD_CUSTOMER_TO_LIST: 'ADD_CUSTOMER_TO_LIST',
  RESET_OFFLINE_CUSTOMERS_LIST: 'RESET_OFFLINE_CUSTOMERS_LIST',
  TOGGLE_JOBS_MODAL_VISIBILITY: 'TOGGLE_JOBS_MODAL_VISIBILITY',
  SET_SELECTED_JOBS_CLIENTS: 'SET_SELECTED_JOBS_CLIENTS',
  RESET_OFFLINE_JOBS_LIST: 'RESET_OFFLINE_JOBS_LIST',
  RESET_SELECTED_JOBS_CLIENTS: 'RESET_SELECTED_JOBS_CLIENTS',
  RESET_OFFLINE_DATA: 'RESET_OFFLINE_DATA',
  RESET_OFFLINE_FETCH_FLAGS: 'RESET_OFFLINE_FETCH_FLAGS',
  SET_STEP: 'SET_STEP',
  SET_CLIENT_IDS: 'SET_CLIENT_IDS',
  SET_DEVICE_IDS: 'SET_DEVICE_IDS',
  DELETE_JOB: 'DELETE_JOB',
  DELETE_REPORT_BY_CUSTOMER: 'DELETE_REPORT_BY_CUSTOMER',
  DELETE_REPORT_BY_JOB_ID: 'DELETE_REPORT_BY_JOB_ID',

  CREATE_REPORT_ENTRIES: 'CREATE_REPORT_ENTRIES',
  DELETE_REPORT_ENTRY: 'DELETE_REPORT_ENTRY',
  RESET_REPORT_ENTRIES: 'RESET_REPORT_ENTRIES',
  FILL_JOB_DETAILS: 'FILL_JOB_DETAILS',
  ADD_PART: 'ADD_PART',
  DELETE_PART: 'DELETE_PART',
  ADD_LABOUR: 'ADD_LABOUR',
  DELETE_LABOUR: 'DELETE_LABOUR',
  SET_REPORT_ID_TO_UPLOAD: 'SET_REPORT_ID_TO_UPLOAD',
  CREATE_REPORT_ENTRY_FOR_NEW_INSPECTION_REPORT:
    'CREATE_REPORT_ENTRY_FOR_NEW_INSPECTION_REPORT',

  SET_OFFLINE_JOBS_REQUEST: 'SET_OFFLINE_JOBS_REQUEST',
  SET_OFFLINE_JOBS_FAILURE: 'SET_OFFLINE_JOBS_FAILURE',
  SET_OFFLINE_JOBS_SUCCESS: 'SET_OFFLINE_JOBS_SUCCESS',

  FETCH_CUSTOMER_LIST_REQUEST: 'FETCH_CUSTOMER_LIST_REQUEST',
  FETCH_CUSTOMER_LIST_SUCCESS: 'FETCH_CUSTOMER_LIST_SUCCESS',
  FETCH_CUSTOMER_LIST_FAILURE: 'FETCH_CUSTOMER_LIST_FAILURE',
  FETCH_CUSTOMER_LIST_CLEAR: 'FETCH_CUSTOMER_LIST_CLEAR',

  FETCH_JOBS_LIST_REQUEST: 'FETCH_JOBS_LIST_REQUEST',
  FETCH_JOBS_LIST_SUCCESS: 'FETCH_JOBS_LIST_SUCCESS',
  FETCH_JOBS_LIST_FAILURE: 'FETCH_JOBS_LIST_FAILURE',
  FETCH_JOBS_LIST_CLEAR: 'FETCH_JOBS_LIST_CLEAR',

  FETCH_REPORTS_LIST_BY_JOB_IDS_REQUEST:
    'FETCH_REPORTS_LIST_BY_JOB_IDS_REQUEST',
  FETCH_REPORTS_LIST_BY_JOB_IDS_SUCCESS:
    'FETCH_REPORTS_LIST_BY_JOB_IDS_SUCCESS',
  FETCH_REPORTS_LIST_BY_JOB_IDS_FAILURE:
    'FETCH_REPORTS_LIST_BY_JOB_IDS_FAILURE',
  FETCH_REPORTS_LIST_BY_JOB_IDS_CLEAR: 'FETCH_REPORTS_LIST_BY_JOB_IDS_CLEAR',

  FETCH_REPORTS_LIST_BY_CUSTOMER_REQUEST:
    'FETCH_REPORTS_LIST_BY_CUSTOMER_REQUEST',
  FETCH_REPORTS_LIST_BY_CUSTOMER_SUCCESS:
    'FETCH_REPORTS_LIST_BY_CUSTOMER_SUCCESS',
  FETCH_REPORTS_LIST_BY_CUSTOMER_FAILURE:
    'FETCH_REPORTS_LIST_BY_CUSTOMER_FAILURE',
  FETCH_REPORTS_LIST_BY_CUSTOMER_CLEAR: 'FETCH_REPORTS_LIST_BY_CUSTOMER_CLEAR',

  FETCH_TEAM_LIST_REQUEST: 'FETCH_TEAM_LIST_REQUEST',
  FETCH_TEAM_LIST_SUCCESS: 'FETCH_TEAM_LIST_SUCCESS',
  FETCH_TEAM_LIST_FAILURE: 'FETCH_TEAM_LIST_FAILURE',
  FETCH_TEAM_LIST_CLEAR: 'FETCH_TEAM_LIST_CLEAR',

  FETCH_REPORTS_TEMPLATE_LIST_REQUEST: 'FETCH_REPORTS_TEMPLATE_LIST_REQUEST',
  FETCH_REPORTS_TEMPLATE_LIST_SUCCESS: 'FETCH_REPORTS_TEMPLATE_LIST_SUCCESS',
  FETCH_REPORTS_TEMPLATE_LIST_FAILURE: 'FETCH_REPORTS_TEMPLATE_LIST_FAILURE',
  FETCH_REPORTS_TEMPLATE_LIST_CLEAR: 'FETCH_REPORTS_TEMPLATE_LIST_CLEAR',

  FETCH_DEVICE_LIST_REQUEST: 'FETCH_DEVICE_LIST_REQUEST',
  FETCH_DEVICE_LIST_SUCCESS: 'FETCH_DEVICE_LIST_SUCCESS',
  FETCH_DEVICE_LIST_FAILURE: 'FETCH_DEVICE_LIST_FAILURE',
  FETCH_DEVICE_LIST_CLEAR: 'FETCH_DEVICE_LIST_CLEAR',

  FETCH_INNER_DEVICE_LIST_REQUEST: 'FETCH_INNER_DEVICE_LIST_REQUEST',
  FETCH_INNER_DEVICE_LIST_SUCCESS: 'FETCH_INNER_DEVICE_LIST_SUCCESS',
  FETCH_INNER_DEVICE_LIST_FAILURE: 'FETCH_INNER_DEVICE_LIST_FAILURE',
  FETCH_INNER_DEVICE_LIST_CLEAR: 'FETCH_INNER_DEVICE_LIST_CLEAR',

  FETCH_PART_LIST_REQUEST: 'FETCH_PART_LIST_REQUEST',
  FETCH_PART_LIST_SUCCESS: 'FETCH_PART_LIST_SUCCESS',
  FETCH_PART_LIST_FAILURE: 'FETCH_PART_LIST_FAILURE',
  FETCH_PART_LIST_CLEAR: 'FETCH_PART_LIST_CLEAR',

  REGISTER_DEVICE_TOKEN_SUCCESS: 'REGISTER_DEVICE_TOKEN_SUCCESS',
  REGISTER_DEVICE_TOKEN_FAILURE: 'REGISTER_DEVICE_TOKEN_FAILURE',
  REGISTER_DEVICE_TOKEN_REQUEST: 'REGISTER_DEVICE_TOKEN_REQUEST',
  REGISTER_DEVICE_TOKEN_CLEAR: 'REGISTER_DEVICE_TOKEN_CLEAR',

  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',
  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',

  UPDATE_NOTIFICATION_REQUEST: 'UPDATE_NOTIFICATION_REQUEST',
  UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
  UPDATE_NOTIFICATION_FAILURE: 'UPDATE_NOTIFICATION_FAILURE',
  UPDATE_NOTIFICATION_CLEAR_STATE: 'UPDATE_NOTIFICATION_CLEAR_STATE',

  CLEAR_UPDATE_KEY: 'CLEAR_UPDATE_KEY',
  UPDATE_IMAGE_DETAILS_FOR_SOCKET: 'UPDATE_IMAGE_DETAILS_FOR_SOCKET',
  CLEAR_IMAGE_DETAILS_FOR_SOCKET: 'CLEAR_IMAGE_DETAILS_FOR_SOCKET',
  SAVE_SIGNATURE_IDS: 'SAVE_SIGNATURE_IDS',
  STORE_SIGNATURE_PROFILE_IN_ANSWERS: 'STORE_SIGNATURE_PROFILE_IN_ANSWERS',

  DOWNLOAD_REPORT_PDF_REQUEST: 'DOWNLOAD_REPORT_PDF_REQUEST',
  DOWNLOAD_REPORT_PDF_SUCCESS: 'DOWNLOAD_REPORT_PDF_SUCCESS',
  DOWNLOAD_REPORT_PDF_FAILURE: 'DOWNLOAD_REPORT_PDF_FAILURE',
  DOWNLOAD_REPORT_PDF_CLEAR: 'DOWNLOAD_REPORT_PDF_CLEAR',
  DELETE_REPORT_PDF_REQUEST: 'DELETE_REPORT_PDF_REQUEST',
  DELETE_REPORT_PDF_SUCCESS: 'DELETE_REPORT_PDF_SUCCESS',
  DELETE_REPORT_PDF_FAILURE: 'DELETE_REPORT_PDF_FAILURE',
  EMAIL_REPORT_PDF_REQUEST: 'EMAIL_REPORT_PDF_REQUEST',
  EMAIL_REPORT_PDF_SUCCESS: 'EMAIL_REPORT_PDF_SUCCESS',
  EMAIL_REPORT_PDF_FAILURE: 'EMAIL_REPORT_PDF_FAILURE',

  ADD_ROW: 'ADD_ROW',
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',

  GENERATE_NEW_ROW_SUCCESS: 'GENERATE_NEW_ROW_SUCCESS',
  GENERATE_NEW_ROW_ERROR: 'GENERATE_NEW_ROW_ERROR',
  GENERATE_NEW_ROW_PROGRESS: 'GENERATE_NEW_ROW_PROGRESS',
  GENERATE_NEW_ROW_CLEAR_UPDATE_STATE: 'GENERATE_NEW_ROW_CLEAR_UPDATE_STATE',

  DELETE_ROW_SUCCESS: 'DELETE_ROW_SUCCESS',
  DELETE_ROW_ERROR: 'DELETE_ROW_ERROR',
  DELETE_ROW_PROGRESS: 'DELETE_ROW_PROGRESS',
  DELETE_ROW_CLEAR_API_STATE: 'DELETE_ROW_CLEAR_API_STATE',

  GET_SERVICE_DUE_LIST_REQUEST: 'GET_SERVICE_DUE_LIST_REQUEST',
  GET_SERVICE_DUE_LIST_SUCCESS: 'GET_SERVICE_DUE_LIST_SUCCESS',
  GET_SERVICE_DUE_LIST_FAILURE: 'GET_SERVICE_DUE_LIST_FAILURE',

  GET_SERVICE_DUE_FOR_COUNT_REQUEST: 'GET_SERVICE_DUE_FOR_COUNT_REQUEST',
  GET_SERVICE_DUE_FOR_COUNT_SUCCESS: 'GET_SERVICE_DUE_FOR_COUNT_SUCCESS',
  GET_SERVICE_DUE_FOR_COUNT_FAILURE: 'GET_SERVICE_DUE_FOR_COUNT_FAILURE',
  GET_DEVICES_FOR_REPORT_SUCCESS: 'GET_DEVICES_FOR_REPORT_SUCCESS',
  GET_DEVICES_FOR_REPORT_FAILURE: 'GET_DEVICES_FOR_REPORT_FAILURE',
  GET_DEVICES_FOR_REPORT_REQUEST: 'GET_DEVICES_FOR_REPORT_REQUEST',
  CLEAR_DEVICES_REPORT_SUCCESS: 'CLEAR_DEVICES_REPORT_SUCCESS',
  SET_ANSWER_TABLE_PARTICULAR_INDEX: 'SET_ANSWER_TABLE_PARTICULAR_INDEX',
  CLEAR_DEVICE_REPORT_DATA: 'CLEAR_DEVICE_REPORT_DATA',

  GET_REPORT_CLIENT_DETAILS_SUCCESS: 'GET_REPORT_CLIENT_DETAILS_SUCCESS',
  GET_REPORT_CLIENT_DETAILS_FAILURE: 'GET_REPORT_CLIENT_DETAILS_FAILURE',
  GET_REPORT_CLIENT_DETAILS_REQUEST: 'GET_REPORT_CLIENT_DETAILS_REQUEST',
  GET_REPORT_CLIENT_DETAILS_CLEAR: 'GET_REPORT_CLIENT_DETAILS_CLEAR',

  INSPECTION_ROW_SHIFT_SUCCESS: 'INSPECTION_ROW_SHIFT_SUCCESS',
  INSPECTION_ROW_SHIFT_ERROR: 'INSPECTION_ROW_SHIFT_ERROR',
  INSPECTION_ROW_SHIFT_PROGRESS: 'INSPECTION_ROW_SHIFT_PROGRESS',
  INSPECTION_ROW_SHIFT_CLEAR_API_STATE: 'INSPECTION_ROW_SHIFT_CLEAR_API_STATE',
  CANCEL_PARTICULAR_REPORT: 'CANCEL_PARTICULAR_REPORT',
  SET_REPORT_NAME_BREADCRUMB: 'SET_REPORT_NAME_BREADCRUMB',
  GET_AUTO_GENERATED_INVOICE_REQUEST: 'GET_AUTO_GENERATED_INVOICE_REQUEST',
  GET_AUTO_GENERATED_INVOICE_SUCCESS: 'GET_AUTO_GENERATED_INVOICE_SUCCESS',
  GET_AUTO_GENERATED_INVOICE_FAILURE: 'GET_AUTO_GENERATED_INVOICE_FAILURE',
  GET_AUTO_GENERATED_INVOICE_CLEAR: 'GET_AUTO_GENERATED_INVOICE_CLEAR',
  UPDATE_DEVICES_IN_REPORT: 'UPDATE_DEVICES_IN_REPORT',
  DELETE_DEVICES_REPORT: 'DELETE_DEVICES_REPORT',
  ADD_DEVICES_REPORT: 'ADD_DEVICES_REPORT',
  SAVE_QR_STRING: 'SAVE_QR_STRING',
  INSERT_ANSWERS_HARDCODED_REPORTS: 'INSERT_ANSWERS_HARDCODED_REPORTS',
  UNSET_HARDCODED_CHILD_ANSWERS: 'UNSET_HARDCODED_CHILD_ANSWERS',
  ADD_HARDCODED_DEVICE: 'ADD_HARDCODED_DEVICE',
  GET_DEVICES_FOR_HARDCODED_REPORT_SUCCESS:
    'GET_DEVICES_FOR_HARDCODED_REPORT_SUCCESS',
  GET_DEVICES_FOR_HARDCODED_REPORT_FAILURE:
    'GET_DEVICES_FOR_HARDCODED_REPORT_FAILURE',
  GET_DEVICES_FOR_HARDCODED_REPORT_REQUEST:
    'GET_DEVICES_FOR_HARDCODED_REPORT_REQUEST',
  CLEAR_DEVICES_HARDCODED_REPORT_SUCCESS:
    'CLEAR_DEVICES_HARDCODED_REPORT_SUCCESS',
  CLEAR_DEVICE_HARDCODED_REPORT_DATA: 'CLEAR_DEVICE_HARDCODED_REPORT_DATA',
  REPLACE_HARDCODED_DEVICES: 'REPLACE_HARDCODED_DEVICES',
  UPDATE_PARTICULAR_INDEX_DEVICE_DETAIL:
    'UPDATE_PARTICULAR_INDEX_DEVICE_DETAIL',
  EDIT_PARTICULAR_DEVICE: 'EDIT_PARTICULAR_DEVICE',
  ADD_CUSTOM_QUESTION: 'ADD_CUSTOM_QUESTION',
  SET_OFF_SWITCHES: 'SET_OFF_SWITCHES',
  UPDATE_QUESTION_JSON: 'UPDATE_QUESTION_JSON',
  SET_CONTRIL_PANEL_ANSWERS: 'SET_CONTRIL_PANEL_ANSWERS',
  UNSET_CONTROL_PANEL_ANSWERS: 'UNSET_CONTROL_PANEL_ANSWERS',
  SET_OFF_SWITCHES_CONTROL_PANEL: 'SET_OFF_SWITCHES_CONTROL_PANEL',
  DELETE_DEVICE_HARDCODED_REPORT: 'DELETE_DEVICE_HARDCODED_REPORT',
  ADD_CUSTOM_QUESTION_CONTROL_PANEL: 'ADD_CUSTOM_QUESTION_CONTROL_PANEL',
  UPDATE_CUSTOM_QUESTION_CONTROL_PANEL: 'UPDATE_CUSTOM_QUESTION_CONTROL_PANEL',
  ADD_PARTICULAR_ID_QUESTION: 'ADD_PARTICULAR_ID_QUESTION',
  GET_HARDCODED_REPORT_CLIENT_DETAILS_SUCCESS:
    'GET_HARDCODED_REPORT_CLIENT_DETAILS_SUCCESS',
  GET_HARDCODED_REPORT_CLIENT_DETAILS_FAILURE:
    'GET_HARDCODED_REPORT_CLIENT_DETAILS_FAILURE',
  GET_HARDCODED_REPORT_CLIENT_DETAILS_REQUEST:
    'GET_HARDCODED_REPORT_CLIENT_DETAILS_REQUEST',
  GET_HARDCODED_REPORT_CLIENT_DETAILS_CLEAR:
    'GET_HARDCODED_REPORT_CLIENT_DETAILS_CLEAR',
  SET_INSPECTION_VIEW_ANSWERS_AND_JSON_DEFAULT_REPORTS:
    'SET_INSPECTION_VIEW_ANSWERS_AND_JSON_DEFAULT_REPORTS',
  INITIALIZE_DATE_FOR_ADD_JOB: 'INITIALIZE_DATE_FOR_ADD_JOB',
  CANCEL_PARTICULAR_HARDCODED_REPORT: 'CANCEL_PARTICULAR_HARDCODED_REPORT',

  UPLOAD_SIGNATURE_SUCCESS: 'UPLOAD_SIGNATURE_SUCCESS',
  UPLOAD_SIGNATURE_FAILURE: 'UPLOAD_SIGNATURE_FAILURE',
  UPLOAD_SIGNATURE_REQUEST: 'UPLOAD_SIGNATURE_REQUEST',
  UPLOAD_SIGNATURE_RESET: 'UPLOAD_SIGNATURE_RESET',

  FETCH_QUESTION_JSON_DEFAULT_SUCCESS: 'FETCH_QUESTION_JSON_DEFAULT_SUCCESS',
  FETCH_QUESTION_JSON_DEFAULT_FAILURE: 'FETCH_QUESTION_JSON_DEFAULT_FAILURE',
  FETCH_QUESTION_JSON_DEFAULT_REQUEST: 'FETCH_QUESTION_JSON_DEFAULT_REQUEST',
  FETCH_QUESTION_JSON_DEFAULT_RESET: 'FETCH_QUESTION_JSON_DEFAULT_RESET',

  SET_SIGNATURE_TYPE: 'SET_SIGNATURE_TYPE',

  COMPLETE_JOB_MANUALLY_REQUEST: 'COMPLETE_JOB_MANUALLY_REQUEST',
  COMPLETE_JOB_MANUALLY_SUCCESS: 'COMPLETE_JOB_MANUALLY_SUCCESS',
  COMPLETE_JOB_MANUALLY_FAILURE: 'COMPLETE_JOB_MANUALLY_FAILURE',
  COMPLETE_JOB_MANUALLY_CLEAR: 'COMPLETE_JOB_MANUALLY_CLEAR',

  CLEAR_REPORT_LIST: 'CLEAR_REPORT_LIST',

  SHOW_LOADER_GLOBAL: 'SHOW_LOADER_GLOBAL',
  HIDE_LOADER_GLOBAL: 'HIDE_LOADER_GLOBAL',

  SET_SIGNATURE_MODAL : 'SET_SIGNATURE_MODAL',
  SET_SIG_PAD_MODAL : 'SET_SIG_PAD_MODAL',
};

import actionConstants from '../constants/ActionConstants';
import ActionDispatcher from '../../api/ActionDispatcher';
import {
  getDevicesApi,
  updateClientDetails,
  getClientDetailsApi,
  updateDefaultReportRealtimeApi,
} from '../../api/apiCallReportInspection';

// import { reportEventHandlers } from '../../socket';

// const inspectionAnswers = ({
//   reportId, reportCategory,
//   reportType, createdFor, createdForName, answers, missedItems,
//   reportJson, parsedJson,
//   templateType,
//   clientDevices,
//   isFinished = false,
//   screenName
// }) => ActionDispatcher(
//   reportInspectionUpdatedAnswer.bind(null, {
//     reportId,
//     reportCategory,
//     reportType,
//     createdFor,
//     createdForName,
//     answers,
//     missedItems,
//     reportJson,
//     parsedJson,
//     clientDevices,
//     templateType,
//     isFinished
//   }),
//   actionConstants.UPDATE_ANSWER_SUCCESS,
//   actionConstants.UPDATE_ANSWER_FAILURE,
//   actionConstants.UPDATE_ANSWER_REQUEST,
//   { screenName }
// );

const setAnswersParticularIndex = (payload, isSync) => (dispatch) => {
  dispatch({
    type: actionConstants.INSERT_ANSWERS_HARDCODED_REPORTS,
    payload,
    isSync,
  });
};

const unsetChildAnswers = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.UNSET_HARDCODED_CHILD_ANSWERS,
    payload,
  });
};

const addDevice = (payload, isSync) => (dispatch) => {
  dispatch({
    type: actionConstants.ADD_HARDCODED_DEVICE,
    payload,
    isSync,
  });
};

export const syncReport = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.SYNC_REPORT,
    payload,
  });
};

export const reverseSyncReport = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.REVERSE_SYNC_REPORT,
    payload,
  });
};

export const updateSyncReport = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.UPDATE_SYNC_REPORT,
    payload,
  });
};

export const clearSyncReport = () => (dispatch) => {
  dispatch({
    type: actionConstants.CLEAR_SYNC_REPORT,
  });
};
export const getDevices = (page, limit, listId) =>
  ActionDispatcher(
    getDevicesApi.bind(null, page, limit, listId),
    actionConstants.GET_DEVICES_FOR_HARDCODED_REPORT_SUCCESS,
    actionConstants.GET_DEVICES_FOR_HARDCODED_REPORT_FAILURE,
    actionConstants.GET_DEVICES_FOR_HARDCODED_REPORT_REQUEST
  );

export const updateDefaultReportRealtime = (payload, cb = () => {}) =>
  ActionDispatcher(
    updateDefaultReportRealtimeApi.bind(null, payload),
    payload.questionJson
      ? actionConstants.UPDATE_REAL_QUESTION
      : actionConstants.UPDATE_REALTIME_SUCCESS,
    actionConstants.UPDATE_REALTIME_FAILURE,
    actionConstants.UPDATE_REALTIME_REQUEST,
    null,
    cb
  );

export const clearDeviceSuccess = () => ({
  type: actionConstants.CLEAR_DEVICES_HARDCODED_REPORT_SUCCESS,
});

export const clearDeviceData = () => (dispatch) => {
  dispatch({
    type: actionConstants.CLEAR_DEVICE_HARDCODED_REPORT_DATA,
  });
};

export const replaceDevices = (payload, isSync) => (dispatch) => {
  dispatch({
    type: actionConstants.REPLACE_HARDCODED_DEVICES,
    payload,
    isSync,
  });
};

export const updateListingDeviceData = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.UPDATE_PARTICULAR_INDEX_DEVICE_DETAIL,
    payload,
  });
};

export const updateClientSignature = (payload) =>
  ActionDispatcher(
    updateClientDetails.bind(null, payload),
    actionConstants.SET_CLIENT_DETAILS_SUCCESS,
    actionConstants.SET_CLIENT_OR_OWNER_DETAILS_FAILURE,
    actionConstants.SET_CLIENT_OR_OWNER_DETAILS_REQUEST
  );

export const getClientDetails = (id) =>
  ActionDispatcher(
    getClientDetailsApi.bind(null, id),
    actionConstants.GET_HARDCODED_REPORT_CLIENT_DETAILS_SUCCESS,
    actionConstants.GET_HARDCODED_REPORT_CLIENT_DETAILS_FAILURE,
    actionConstants.GET_HARDCODED_REPORT_CLIENT_DETAILS_REQUEST
  );

export const editDevice = (payload, isSync) => (dispatch) => {
  dispatch({
    type: actionConstants.EDIT_PARTICULAR_DEVICE,
    payload,
    isSync,
  });
};

export const addCustomQuestionToJson = (payload, isSync) => (dispatch) => {
  dispatch({
    type: actionConstants.ADD_CUSTOM_QUESTION,
    payload,
    isSync,
  });
};

export const addControlPanelFromQr = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.ADD_SYSTEM_QR,
    payload,
  });
};

export const addDevicesFromQr = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.ADD_DEVICES_QR,
    payload,
  });
};

export const setOffSwitches = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.SET_OFF_SWITCHES,
    payload,
  });
};

export const updatedQuestionJson = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.UPDATE_QUESTION_JSON,
    payload,
  });
};

export const setControlPanelAnswers = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.SET_CONTRIL_PANEL_ANSWERS,
    payload,
  });
};

export const unsetControlPanelAnswers = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.UNSET_CONTROL_PANEL_ANSWERS,
    payload,
  });
};

export const setOffSwitchesControlPanel = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.SET_OFF_SWITCHES_CONTROL_PANEL,
    payload,
  });
};

export const deleteDevice = (payload, isSync) => (dispatch) => {
  dispatch({
    type: actionConstants.DELETE_DEVICE_HARDCODED_REPORT,
    payload,
    isSync,
  });
};

export const addCustomQuestionToControlPanelJson = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.ADD_CUSTOM_QUESTION_CONTROL_PANEL,
    payload,
  });
};

export const updateControlPanelQuestionJson = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.UPDATE_CUSTOM_QUESTION_CONTROL_PANEL,
    payload,
  });
};

export const addCustomQuestionToParticularJsonId = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.ADD_PARTICULAR_ID_QUESTION,
    payload,
  });
};

export const clearClientDetailSuccess = () => (dispatch) => {
  dispatch({
    type: actionConstants.GET_HARDCODED_REPORT_CLIENT_DETAILS_CLEAR,
  });
};

export const clearHardCodeInspectionState = () => (dispatch) => {
  dispatch({
    type: actionConstants.RESET_UPDATE_INSPECTION_ARRAY_INITIAL_STATE,
  });
};

export const deleteReportDefaultReport = (payload) => ({
  type: actionConstants.CANCEL_PARTICULAR_HARDCODED_REPORT,
  payload,
});

export const setAnswersForViewingReports = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.SET_INSPECTION_VIEW_ANSWERS_AND_JSON_DEFAULT_REPORTS,
    payload,
  });
};

export const toggleSignatureModal = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.SET_SIGNATURE_MODAL,
    payload,
  });
};

export const setSigPadModal = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.SET_SIG_PAD_MODAL,
    payload,
  });
};

export {
  //   inspectionAnswers,
  setAnswersParticularIndex,
  unsetChildAnswers,
  addDevice,
};

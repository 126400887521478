import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
  REPORT_FILTER_LIST_HEADER,
  REPORT_FILTER_LIST_HEADER_FOR_EXTINGUISHER,
  REPORT_CATEGORIES,
  reportCategoriesWithRoutes,
  reportFilters,
} from '../../utils/Constants';
import Button from '../../components/Button';
import PageLoader from '../../components/PageLoader';
import {
  toolTips,
  reportFilterTipsExt,
  reportFilterTips,
} from '../../utils/toolTips';
import ToolTip from '../../components/ToolTip';
import ServiceDueGrid from './ServicesDueGrid';

const getReportList = (path) => {
  const pathArray = path.split('/');
  const selectedFilter = pathArray[pathArray.length - 1];
  if (selectedFilter === REPORT_CATEGORIES.extinguiser){
    let finalList = [...REPORT_FILTER_LIST_HEADER_FOR_EXTINGUISHER]
    finalList.splice(4, 0, 'Services Done') // insert after service due
    return finalList
  }
  return REPORT_FILTER_LIST_HEADER;
};

const getReportListTips = (path) => {
  const pathArray = path.split('/');
  const selectedFilter = pathArray[pathArray.length - 1];
  if (selectedFilter === REPORT_CATEGORIES.extinguiser) {
    return reportFilterTipsExt;
  }
  return reportFilterTips;
};

/**
 * Shows the header content for filtered report list.
 * @param {*} props
 */
export const ReportFilterList = (props) => {
  const {
    showFilterList, // Method called on selecting filter.
    selectedFilterName, // Contains selected filter name.
    handleClickSave,
    exportList,
    servicesDueDetails,
    isReportNeeded,
  } = props;

  const filtersTooltips = getReportListTips(props.location.pathname);
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center top-search-field">
        <h1>
          <FormattedMessage
            id={reportCategoriesWithRoutes[props.match.params.name] || ' '}
            defaultMessage={
              reportCategoriesWithRoutes[props.match.params.name] || ' '
            }
          />
        </h1>
      </div>
      <div className="tab-list mr-bt-24" style={{ cursor: 'pointer' }}>
        <ul>
          {getReportList(props.location.pathname).map((listName, index) =>
            isReportNeeded === false &&
            listName === 'Incomplete Reports' ? null : (
              <>
                <li
                  key={index}
                  onClick={() => showFilterList(listName)}
                  className={
                    selectedFilterName === listName ? 'active' : undefined
                  }
                  data-tip={props.intl.formatMessage({
                    id: filtersTooltips[index],
                  })}
                  data-for={filtersTooltips[index]}
                >
                  <FormattedMessage id={listName} defaultMessage={listName} />
                </li>
              </>
            )
          )}
        </ul>
        {selectedFilterName !== 'Incomplete Reports' &&
          isReportNeeded !== false &&
          selectedFilterName !== reportFilters.serviceDue && (
            <Button
              text="Start"
              onBtnClick={handleClickSave}
              dataTip={toolTips.customerHome.start}
              dataFor={toolTips.customerHome.start}
              place="left"
            />
          )}
      </div>
      {selectedFilterName === reportFilters.serviceDue && (
        <>
          {/**servicesDueDetails.serviceDueForCount.serviceDueForCount.length ? (
            <div>
              <span className="services-due-heading">
                <FormattedMessage
                  id="Extinguisher services due"
                  defaultMessage="Extinguisher services due XD"
                />
              </span>
              {servicesDueDetails.serviceDueForCount.isFetching ? (
                <PageLoader />
              ) : (
                <ServiceDueGrid
                  serviceDueForCount={servicesDueDetails.serviceDueForCount}
                />
              )}
            </div>
          ) : null**/}
          <div className="service-due-headings-wrapper">
            <span className="services-due-heading">
              <FormattedMessage
                id="Extinguisher due for services"
                defaultMessage="Extinguisher due for services"
              />
            </span>
            <Button text="Export List" onBtnClick={() => exportList(true)} />
          </div>
        </>
      )}
    </React.Fragment>
  );
};

ReportFilterList.propTypes = {
  showFilterList: PropTypes.func,
  selectedFilterName: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.object,
  handleClickSave: PropTypes.func,
  exportList: PropTypes.func,
  servicesDueDetails: PropTypes.object,
  intl: intlShape.isRequired,
  isReportNeeded: PropTypes.any,
};

export default withRouter(injectIntl(ReportFilterList));

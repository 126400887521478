export const tableHeader = [
  {
    title: 'Frequency',
    isSortable: false,
  },
  {
    title: 'Completion Date',
    isSortable: false,
  },
  {
    title: 'Name',
    isSortable: false,
  },
  {
    title: ' ',
    isSortable: false,
  },
];

export const tableHeaderStartDate = [
  {
    title: 'Frequency',
    isSortable: false,
  },
  {
    title: 'Date Started',
    isSortable: false,
  },
  {
    title: 'Name',
    isSortable: false,
  },
  {
    title: ' ',
    isSortable: false,
  },
];

export const tableHeaderServicesDue = [
  {
    title: 'Type',
    isSortable: false,
    width: '10%',
  },
  {
    title: 'Location',
    isSortable: false,
    width: '25%',
  },
  {
    title: 'Floor',
    isSortable: false,
    width: '10%',
  },
  {
    title: 'Make.',
    isSortable: false,
    width: '12%',
  },
  {
    title: 'Serial #',
    isSortable: false,
    width: '13%',
  },
  {
    title: 'Size',
    isSortable: false,
    width: '10%',
  },
  {
    title: 'Due For',
    isSortable: false,
    width: '10%',
  },
  {
    title: 'Due date',
    isSortable: false,
    width: '10%',
  },
];

export const tableHeaderServicesDone = [
  {
    title: 'Type',
    isSortable: false,
    width: '10%',
  },
  {
    title: 'Location',
    isSortable: false,
    width: '25%',
  },
  {
    title: 'Floor',
    isSortable: false,
    width: '10%',
  },
  {
    title: 'Make.',
    isSortable: false,
    width: '12%',
  },
  {
    title: 'Serial #',
    isSortable: false,
    width: '13%',
  },
  {
    title: 'Size',
    isSortable: false,
    width: '10%',
  },
  {
    title: 'Due For',
    isSortable: false,
    width: '10%',
  },
  {
    title: 'Done at',
    isSortable: false,
    width: '10%',
  },
];
